import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Button } from '../../../components/Button';

interface FooterProps {
  formName: string;
  isSubmitEnabled: boolean;
  onCancel: () => void;
  onSubmit: () => void;
}

export const Footer: FC<FooterProps> = ({
  formName,
  isSubmitEnabled,
  onCancel,
  onSubmit,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { t } = useTranslation();

  const handleSubmitForm = useCallback(() => {
    setIsSubmitting(true);
    onSubmit();
  }, [onSubmit]);

  return (
    <Wrapper>
      <Name>{formName}</Name>
      <ButtonWrapper>
        <Button variant="outline" onClick={() => onCancel()}>
          {t('views.form.controls.cancel')}
        </Button>
        <Button
          variant="primary"
          disabled={!isSubmitEnabled || isSubmitting}
          onClick={handleSubmitForm}
        >
          {t('views.form.controls.submit')}
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  gap: 16px;
`;

const Name = styled.div`
  color: ${({ theme }): string => theme.colors.white};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    visibility: hidden;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
