import { FC } from 'react';
import styled, { DefaultTheme, useTheme } from 'styled-components';
import { Icon, IconTypes } from '../Icon';
import { isMobileDevice } from '../../helpers';
import { useSharingState } from '../../hooks';

export enum IconButtonTypes {
  cameraToggle = 'cameraToggle',
  micToggle = 'micToggle',
  cycleCamera = 'cycleCamera',
  screenShare = 'screenShare',
}

export interface IconButtonProps extends React.HTMLProps<HTMLButtonElement> {
  icon: IconTypes;
  variant: IconButtonTypes;
  label?: string;
  active?: boolean;
  testId?: string;
}

interface IconProps {
  variant: IconButtonTypes;
  active: boolean;
  isVideoMinimized?: boolean;
  isSharing?: boolean;
}

interface ButtonWrapperProps {
  variant: IconButtonTypes;
  isVideoMinimized?: boolean;
  isSharing?: boolean;
}

interface LabelProps {
  variant: IconButtonTypes;
  active: boolean;
  htmlFor: string;
  disabled: boolean;
}

interface StyleArgs {
  theme: DefaultTheme;
  active: boolean;
  variant: string;
}

const { cameraToggle, micToggle, screenShare } = IconButtonTypes;

export const IconButton: FC<IconButtonProps> = ({
  icon,
  variant,
  label = '',
  active = false,
  disabled = false,
  testId = '',
  onClick,
}) => {
  const theme = useTheme();
  const {
    isFullScreen,
    isFormSharing,
    isMediaSharing,
    isParticipantsScreenShared,
  } = useSharingState();
  const isSharing =
    isFormSharing || isMediaSharing || isParticipantsScreenShared;

  const getIconColor = () => {
    if (!active && (variant === cameraToggle || variant === micToggle)) {
      return theme.colors.iconButtonGrey;
    }

    return theme.colors.white;
  };

  return (
    <Button
      id={label}
      variant={variant}
      onClick={onClick}
      isVideoMinimized={isFullScreen}
      isSharing={isSharing}
      disabled={disabled}
      data-testid={testId}
    >
      <ButtonIcon
        variant={variant}
        active={active}
        isVideoMinimized={isFullScreen}
        isSharing={isSharing}
      >
        <Icon type={IconTypes[icon]} color={getIconColor()} />
      </ButtonIcon>
      {label && (
        <Label
          htmlFor={label}
          variant={variant}
          active={active}
          disabled={disabled}
        >
          {label}
        </Label>
      )}
    </Button>
  );
};

const getButtonIconBackgroundColor = ({
  theme,
  active,
  variant,
}: StyleArgs): string => {
  if (!active && (variant === cameraToggle || variant === micToggle)) {
    return theme.colors.white;
  }

  if (active && variant === screenShare) {
    return theme.colors.active;
  }

  return theme.colors.iconButtonGrey;
};

const getLabelColor = ({ theme, active, variant }: StyleArgs): string => {
  if (variant === screenShare && active) {
    return theme.colors.active;
  }

  return theme.colors.white;
};

const hideButtonsForMobileView = (variant: string): string => {
  if (variant === screenShare && isMobileDevice()) {
    return 'none';
  }

  return 'flex';
};

const Button = styled.button<ButtonWrapperProps>`
  display: ${({ variant }) => hideButtonsForMobileView(variant)};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: ${({ isVideoMinimized }) => (isVideoMinimized ? '40px' : '80px')};
  padding: 0;
  cursor: pointer;
  border: none;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.almostBlack};

  &:disabled {
    cursor: not-allowed;
    i {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    height: 36px;
    overflow: unset;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    height: ${({ isSharing }) => (isSharing ? '40px' : '80px')};
  }
`;

const ButtonIcon = styled.i<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ isVideoMinimized }) => (isVideoMinimized ? '40px' : '36px')};
  height: ${({ isVideoMinimized }) => (isVideoMinimized ? '40px' : '36px')};
  border-radius: 12px;
  cursor: pointer;
  background-color: ${(props) => getButtonIconBackgroundColor(props)};

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    width: ${({ isSharing }) => (isSharing ? '40px' : '36px')};
  }
`;

const Label = styled.label<LabelProps>`
  font-size: 16px;
  margin-top: 8px;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${(props) => getLabelColor(props)};
  opacity: ${({ disabled }) => (disabled ? '0.4' : '1')};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    display: none;
  }
`;
