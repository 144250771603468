import { RefObject } from 'react';
import { Howl } from 'howler';
import { VideoPreferences } from '../../hooks';
import { getStreamName } from '../../services/helpers';
import { NotificationTypes, YsuraVideoEvents } from '../../services/Enums';
import assetJoin from '../../assets/sounds/join.mp3';
import assetLeave from '../../assets/sounds/leave.mp3';
import {
  YsVideoProviderSession,
  YsVideoPublisher,
} from '../../services/rtcClient';

const audioJoin = new Howl({
  src: [assetJoin],
  volume: 0.8,
});

const audioLeave = new Howl({
  src: [assetLeave],
  volume: 0.8,
});

interface VideoContols {
  token: string;
  videoPreferences: VideoPreferences;
  session: YsVideoProviderSession;
  subscriberRef: RefObject<HTMLElement>;
  publisherRef: RefObject<HTMLElement>;
  isVideoEnabled: boolean;
  isMicEnabled: boolean;
  onSubscriberConnected(): void;
  onSubscriberDisconnected(): void;
}

interface InitializedVideo {
  publisher: YsVideoPublisher;
}

export const initializeVideo = ({
  token,
  videoPreferences,
  session,
  publisherRef,
  subscriberRef,
  onSubscriberConnected,
  onSubscriberDisconnected,
  isVideoEnabled,
  isMicEnabled,
}: VideoContols): InitializedVideo => {
  const streamName = getStreamName();
  const fitMode = videoPreferences?.fitMode;

  const handleError = (err: string, message: string): void => {
    // TODO: handle error properly
    // eslint-disable-next-line no-console
    console.error(err);
    const errorEvent = new CustomEvent(YsuraVideoEvents.error, {
      detail: {
        notification: {
          message,
          type: NotificationTypes.error,
        },
      },
    });

    window.dispatchEvent(errorEvent);
  };

  let publisher: YsVideoPublisher | null = null;
  publisher = session.initPublisher(
    publisherRef?.current ? publisherRef.current : undefined,
    {
      fitMode,
      name: streamName,
      videoSource: isVideoEnabled,
      audioSource: isMicEnabled,
    }
  );

  if (subscriberRef?.current) {
    session.registerSubscriber(
      subscriberRef?.current,
      { fitMode: 'contain' },
      (e) => e && handleError(e, 'Could not subscribe to video.')
    );
  }

  // Connect to the session
  session
    .connect(token)
    .then(() => {
      if (publisher) {
        session.publish(publisher);
      }
    })
    .catch((err) => {
      handleError(err, 'An error occurred.');
    });

  session.setOnSubscriberConnected(() => {
    onSubscriberConnected();
    audioJoin.play();
  });

  session.setOnSubscriberDisconnected(() => {
    onSubscriberDisconnected();
    audioLeave.play();
  });

  return { publisher };
};
