import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Logo } from '../../assets/images/logo.svg';

export const Header: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StyledLogo />
      <Title>{t('header.title')}</Title>
    </Wrapper>
  );
};

const Wrapper = styled.header`
  display: flex;
  align-items: center;
  height: 56px;
`;

const StyledLogo = styled(Logo)`
  height: 36px;
  width: 36px;
  fill: ${({ theme }) => theme.colors.white};
`;

const Title = styled.span`
  color: ${({ theme }) => theme.colors.white};
  font-size: 25px;
  margin-left: 5px;
`;
