import React from 'react';
import styled from 'styled-components';

export const OpenViduVideoComponent: React.FC = () => {
  return (
    <Wrapper style={{ width: '100%', height: '100%' }}>
      <OVWrapper className="OT_mirrored OT_root OT_publisher OT_fit-mode-cover">
        <Publisher
          data-testid="ysura-video-publisher"
          id="publisher"
          className="OT_widget-container"
        />
      </OVWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const OVWrapper = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Publisher = styled.div`
  width: 100%;
  height: 100%;
`;
