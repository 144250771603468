import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { PhoneOff } from 'react-feather';
import { Button } from '../Button';
import { ReactComponent as YsuraLogo } from '../../assets/images/logo.svg';
import { useSharingState } from '../../hooks';
import { useComChannel } from '../../services/ComChannel';

interface RoomHeaderProps {
  isSharing: boolean;
}

export const RoomHeader: React.FC<RoomHeaderProps> = ({ isSharing }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roomId } = useParams();
  const { isFullScreen } = useSharingState();
  const { leaveCall } = useComChannel();

  const handleLeaveCall = () => {
    leaveCall();
    navigate(`/feedback/${roomId}`);
  };

  return (
    <Wrapper isVideoMinimized={isFullScreen} isSharing={isSharing}>
      <LogoContainer isSharing={isSharing}>
        <Logo data-testid="ysura_logo" />
      </LogoContainer>

      <LargeScreenButtonContainer
        isSharing={isSharing}
        data-testid="large_screen_button"
      >
        <Button
          variant="danger"
          onClick={handleLeaveCall}
          customStyles={{
            height: isFullScreen ? '40px' : '',
            width: isFullScreen ? '40px' : '',
            padding: isFullScreen ? '0px' : '',
          }}
        >
          {isFullScreen ? (
            <PhoneOff data-testid="phone_off_icon" />
          ) : (
            t('views.room.leaveCall')
          )}
        </Button>
      </LargeScreenButtonContainer>

      <SmallScreenButtonContainer
        isSharing={isSharing}
        data-testid="small_screen_button"
      >
        <Button
          variant="danger"
          onClick={handleLeaveCall}
          customStyles={{
            height: '40px',
            width: '40px',
            padding: '0px',
          }}
        >
          <PhoneOff />
        </Button>
      </SmallScreenButtonContainer>
    </Wrapper>
  );
};

type WrapperAndContainerProps = {
  isVideoMinimized?: boolean;
  isSharing?: boolean;
};

// When sharing & isFullScreen or mobile landscape view are true, show items in 1 column
const Wrapper = styled.header<WrapperAndContainerProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: ${({ isVideoMinimized: mv }) => (mv ? 'column' : 'row')};
  row-gap: ${({ isVideoMinimized }) => (isVideoMinimized ? '16px' : '0')};

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    flex-direction: ${({ isSharing }) => (isSharing ? 'column' : 'row')};
  }
`;

// When small screen and landscape mode, remove the logo
const LogoContainer = styled.div<WrapperAndContainerProps>`
  height: 40px;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    display: ${({ isSharing }) => (isSharing ? 'none' : 'block')};
  }
`;

const Logo = styled(YsuraLogo)`
  fill: ${({ theme }) => theme.colors.white};
  height: 40px;
  width: 40px;
`;

const LargeScreenButtonContainer = styled.div<WrapperAndContainerProps>`
  display: block;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    display: ${({ isSharing }) => (isSharing ? 'none' : 'block')};
  }
`;

const SmallScreenButtonContainer = styled.div<WrapperAndContainerProps>`
  display: none;
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    display: ${({ isSharing }) => (isSharing ? 'block' : 'none')};
  }
`;
