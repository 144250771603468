import { useCallback } from 'react';
import { SharingState, useYroomState } from './useYroomState';

export type UseSharingState = SharingState & {
  setSharingState: (sharing: Partial<SharingState>) => void;
};

export const useSharingState = (): UseSharingState => {
  const { yroomState, setYroomState } = useYroomState();

  const setSharingState = useCallback(
    (sharingState: Partial<SharingState>) => {
      setYroomState((oldState) => {
        const newSharingState = {
          ...oldState.sharing,
          ...sharingState,
        };

        return { ...oldState, sharing: newSharingState };
      });
    },
    [setYroomState]
  );

  return {
    ...yroomState.sharing,
    setSharingState,
  };
};
