export enum YsuraVideoEvents {
  disconnected = 'ysura-video:room:disconnected',
  connected = 'ysura-video:room:connected',
  failedToConnect = 'ysura-video:room:failedToConnect',
  organizerNotification = 'ysura-video:room:organizerNotification',
  shareScreen = 'ysura-video:room:shareScreen',
  cycleCamera = 'ysura-video:camera:cycle',
  toggleCamera = 'ysura-video:camera:toggle',
  toggleMic = 'ysura-video:mic:toggle',
}
