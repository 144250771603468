import { ButtonHTMLAttributes, FC, ReactNode } from 'react';
import styled from 'styled-components';
import { LoadingIcon } from '../LoadingIcon';

interface CustomStyles {
  height?: string;
  width?: string;
  padding?: string;
}
export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  isLoading?: boolean;
  customStyles?: CustomStyles;
  variant?:
    | 'primary'
    | 'danger'
    | 'ghost'
    | 'greyGhost'
    | 'outline'
    | 'transparent';
}

export const Button: FC<ButtonProps> = ({
  children,
  isLoading = false,
  variant = 'primary',
  customStyles = {},
  ...rest
}) => {
  return (
    <StyledButton
      {...rest}
      isLoading={isLoading}
      variant={variant}
      customStyles={customStyles}
    >
      {isLoading && <LoadingIcon />}
      {children}
    </StyledButton>
  );
};

const StyledButton = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ customStyles }) =>
    (customStyles && customStyles.height) || 'auto'};
  width: ${({ customStyles }) =>
    (customStyles && customStyles.width) || 'auto'};
  padding: ${({ customStyles }) =>
    (customStyles && customStyles.padding) || '8px 16px'};
  border-radius: 16px;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  cursor: pointer;
  font-size: 16px;
  line-height: 24px;
  transition: 0.2s all ease;
  :disabled {
    background: ${({ theme }) => theme.colors.disabled};
    cursor: not-allowed;
    :hover {
      background: ${({ theme }) => theme.colors.disabled};
    }
  }

  ${({ variant, theme }) =>
    variant === 'primary' &&
    `
      background-color: ${theme.colors.primary.main};

      &:hover {
        background-color: ${theme.colors.primary.light};
      }

      &:active {
        background-color: ${theme.colors.primary.dark};
      }
    `}

  ${({ variant, theme }) =>
    variant === 'danger' &&
    `
      background-color: ${theme.colors.danger};
    `}

  ${({ theme, variant }) =>
    variant === 'ghost' &&
    `
      background-color: transparent;
      border: none;
      color: ${theme.colors.primary.main};
    `}

  ${({ theme, variant }) =>
    variant === 'greyGhost' &&
    `
      border: none;
      background-color: transparent;
      color: ${theme.colors.gray};
    `}

  ${({ theme, variant }) =>
    variant === 'outline' &&
    `
      background-color: transparent;
      border: 1px solid ${theme.colors.white};
      :hover {
        color: ${theme.colors.black};
        background: ${theme.colors.white};
      }
    `}

  ${({ theme, variant }) =>
    variant === 'transparent' &&
    `
      background-color: ${theme.colors.transparentBlack};
    `}

  ${({ isLoading, theme }) =>
    isLoading &&
    `
      background: ${theme.colors.disabled};
      color: ${theme.colors.white};
      display: flex;
      align-items: center;
      gap: 10px;
      pointer-events: none;
      :hover {
        background: ${theme.colors.disabled};
      }
    `}
`;
