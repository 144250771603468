import styled from 'styled-components';

export const Container = styled.div`
  background: ${({ theme }) => theme.backgroundColor.light};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 16px;
  width: 326px;
  max-width: 100%;
`;
