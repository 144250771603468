import styled from 'styled-components';
import { Outlet } from 'react-router-dom';
import { Header } from '../../components/Header';
import { Footer } from '../../components/Footer';

export const MainLayout: React.FC = () => {
  return (
    <Wrapper>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.backgroundColor.gradient.dark};
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 32px;
`;

const Content = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  padding: 32px 0;
`;
