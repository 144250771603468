import React from 'react';
import styled from 'styled-components';
import { ChevronLeft, ChevronRight } from 'react-feather';

interface PaginationButtonProps extends React.HTMLProps<HTMLButtonElement> {
  paginationButtonType: 'back' | 'forward';
  testId?: string;
}

export const PaginationButton: React.FC<PaginationButtonProps> = ({
  paginationButtonType,
  onClick,
  testId,
}) => {
  return (
    <Button
      paginationButtonType={paginationButtonType}
      onClick={onClick}
      data-testid={testId}
    >
      {paginationButtonType === 'back' ? (
        <IconContainer>
          <ChevronLeft />
        </IconContainer>
      ) : (
        <IconContainer>
          <ChevronRight />
        </IconContainer>
      )}
    </Button>
  );
};

const Button = styled.button<PaginationButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: none;
  background-color: ${({ theme }) => theme.colors.iconButtonGrey};
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 50%;
  transform: translateY(50%);
  left: ${({ paginationButtonType }): string =>
    paginationButtonType === 'back' ? '-8px' : 'unset'};
  right: ${({ paginationButtonType }): string =>
    paginationButtonType === 'forward' ? '-8px' : 'unset'};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    bottom: -4px;
    transform: translateY(100%);
    left: ${({ paginationButtonType }): string =>
      paginationButtonType === 'back' ? '0' : 'unset'};
    right: ${({ paginationButtonType }): string =>
      paginationButtonType === 'forward' ? '0' : 'unset'};
  }
`;

// Needed for icons to be rendered properly on iOS devices
const IconContainer = styled.div`
  display: flex;
`;
