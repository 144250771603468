import { FC, useState } from 'react';
import styled from 'styled-components';
import { FeedbackForm } from './FeedbackForm';
import { PostFeedback } from './PostFeedback';

export const Feedback: FC = () => {
  const [isSubmitted, setSubmitted] = useState(false);

  return (
    <Wrapper>
      {isSubmitted ? (
        <PostFeedback />
      ) : (
        <FeedbackForm onFormSubmitted={() => setSubmitted(true)} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor.light};
  border-radius: 16px;
  padding: 24px 16px;
  width: 320px;
`;
