const LOCAL_ORGANIZER_URL = 'http://localhost:3000/';
const ORGANIZER_URL_PREFIX = 'organizer-';
const OPENVIDU_URL_SUFFIX = '-ov';

export const isLocalHost = (): boolean => {
  const { hostname } = window.location;
  const host = hostname.split('.')?.[0] || hostname;

  return !!['localhost', '10', '127', '172', '192'].find((it) => it === host);
};

export const getOrganizerUrl = (): string => {
  if (isLocalHost()) {
    return LOCAL_ORGANIZER_URL;
  }

  const { realm, isOVClient } = parseRealm();
  const host = `${realm}${isOVClient ? OPENVIDU_URL_SUFFIX : ''}`;

  const { href } = window.location;
  const organizerUrl = href.replace(
    `//${host}.`,
    `//${ORGANIZER_URL_PREFIX}${host}.`
  );

  return organizerUrl;
};

// in case of local development retrieve the realm from the environment
// in production setup, retrieve it from the current location (e.g. https://dummy.yroom.app => "dummy")
const parseRealm = () => {
  let realmCandidate;
  if (isLocalHost()) {
    realmCandidate = process.env.REACT_APP_REALM || '';
  } else {
    const { hostname } = window.location;

    realmCandidate = hostname.split('.')?.[0] || hostname;
  }

  // if the realm has the '-ov' suffix, we remove it
  const ovMatcher = new RegExp(`${OPENVIDU_URL_SUFFIX}$`);
  const isOVClient = ovMatcher.test(realmCandidate);
  let realm = realmCandidate;
  if (isOVClient) {
    realm = realmCandidate.replace(OPENVIDU_URL_SUFFIX, '');
  }

  return { realm, isOVClient };
};

export const getRealm = (): string => {
  const { realm } = parseRealm();
  return realm;
};

// Gets the URL of the state-server from the environment variables depending on the realm
// If the realm ends in '-ov' (e.g. https://dummy-ov.yroom.app) we choose the OpenVidu state-server
export const getStateServerUrl = (): string => {
  const stateServerUrlOT = String(process.env.REACT_APP_WS_URL);
  const stateServerUrlOV = String(process.env.REACT_APP_WS_URL_OV);

  const { isOVClient } = parseRealm();

  return isOVClient ? stateServerUrlOV : stateServerUrlOT;
};
