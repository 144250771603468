import { RefObject, useLayoutEffect, useState } from 'react';

const initialState = { height: 0, width: 0 };

export const useContainerSize = (
  containerRef: RefObject<HTMLDivElement>
): typeof initialState => {
  const [size, setSize] = useState(initialState);

  useLayoutEffect(() => {
    function updateSize() {
      if (containerRef.current) {
        setSize({
          height: containerRef.current.clientHeight,
          width: containerRef.current.clientWidth,
        });
      }
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [containerRef]);

  return size;
};
