import { ElementType } from 'react';
import { toast, TypeOptions } from 'react-toastify';
import { useTranslation } from 'react-i18next';

import { NotificationContainer } from './NotificationContainer';

interface NotificationHook {
  showNotification: (message: string, type: TypeOptions | undefined) => void;
  showInfoNotification: (message: string) => void;
  showSuccessNotification: (message: string) => void;
  showWarningNotification: (message: string) => void;
  NotificationContainer: ElementType;
}

export const useNotifications = (): NotificationHook => {
  const { t } = useTranslation();

  const showNotification = (message: string, type: TypeOptions | undefined) => {
    toast(t(message), {
      type: type ?? 'default',
    });
  };

  const showSuccessNotification = (message: string) => {
    showNotification(message, 'success');
  };

  const showWarningNotification = (message: string) => {
    showNotification(message, 'warning');
  };

  const showInfoNotification = (message: string) => {
    showNotification(message, 'info');
  };

  return {
    showNotification,
    showInfoNotification,
    showSuccessNotification,
    showWarningNotification,
    NotificationContainer,
  };
};
