import { FC } from 'react';
import styled, { useTheme } from 'styled-components';
import { Icon, IconTypes } from '../../../components/Icon';
import { StatusType } from '../type';

type StatusIconProps = {
  status: StatusType;
  title: string;
  type: IconTypes;
};

export const StatusIcon: FC<StatusIconProps> = ({ status, title, type }) => {
  const theme = useTheme();
  return (
    <LabeledIcon>
      <IconContainer title="Video Icon" status={status}>
        <Icon color={theme.colors.disabled} type={type} />
      </IconContainer>
      <Label title={title}>{title}</Label>
    </LabeledIcon>
  );
};

const LabeledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;
  height: 84px;
  width: 100px;
  max-width: calc(100% / 3);
  svg {
    height: 24px;
    width: 24px;
    stroke: white;
  }
`;

type IconContainerProps = {
  status: string;
};

const IconContainer = styled.div<IconContainerProps>`
  background: ${({ theme, status }) => {
    switch (status) {
      case 'success':
        return theme.colors.success;
      case 'error':
        return theme.colors.error;
      default:
        return theme.colors.iconButtonGrey;
    }
  }};
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
`;

const Label = styled.div`
  color: ${({ theme }) => theme.colors.iconButtonGrey};
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;
