import { v4 as uuid } from 'uuid';
import {
  PropsWithChildren,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled from 'styled-components';
import { useComChannel } from '../../services/ComChannel';
import { Pointer } from '../../services/ComChannel/AttendeeComChannel';
import { PointerAnimation } from './PointerAnimation';

type MediaPointerProps = {
  type: 'HTML' | 'PDF';
  iframe?: RefObject<HTMLIFrameElement>;
};

export const MediaPointer = ({
  children,
  type,
  iframe,
}: PropsWithChildren<MediaPointerProps>) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { broadcastPointerShow, onPointerShown } = useComChannel();
  const [pointers, setPointers] = useState<Array<Pointer>>([]);
  const [timeoutHandle, setTimeoutHandle] = useState<NodeJS.Timeout>();

  const showPointer = useCallback((pointerEvent: Pointer) => {
    setPointers((prevState) => {
      return [...prevState, pointerEvent];
    });
  }, []);

  const broadcastPointer = useCallback(
    (pointerEvent: Pointer) => {
      broadcastPointerShow(pointerEvent);
    },
    [broadcastPointerShow]
  );

  const handleMouseDown = (x: number, y: number) => {
    // Map the position of the pointer in screen coords in percentage of the viewport width/height
    const boundingRect = wrapperRef.current?.getBoundingClientRect();

    if (boundingRect) {
      const mx = x - boundingRect.left;
      const my = y - boundingRect.top;
      const pointerEvent: Pointer = {
        id: uuid(),
        posX: x,
        posY: y,
        rx: mx / boundingRect.width,
        ry: my / boundingRect.height,
        isOrganizer: false,
        source: 'overlay',
      };

      // Show pointer after 500ms if the mouse button is not released
      setTimeoutHandle(
        setTimeout(() => {
          showPointer(pointerEvent);
          broadcastPointer(pointerEvent);
        }, 500)
      );
    }
  };

  const handleMouseUp = () => {
    if (timeoutHandle) {
      clearTimeout(timeoutHandle);
    }
  };

  useEffect(() => {
    const handleWindowMessage = (event: MessageEvent) => {
      if (
        event.data &&
        typeof event.data === 'string' &&
        event.data.indexOf('pointer') > 0
      ) {
        const data = JSON.parse(event.data);
        const boundingRect = wrapperRef.current?.getBoundingClientRect();

        if (boundingRect && data.eventName === 'pointerRequested') {
          const pointerEvent: Pointer = {
            id: uuid(),
            posX: data.pointerRequest.posX + boundingRect.left,
            posY: data.pointerRequest.posY + boundingRect.top,
            rx: data.pointerRequest.rx,
            ry: data.pointerRequest.ry,
            isOrganizer: false,
            source: 'iframe',
          };
          showPointer(pointerEvent);
          broadcastPointer(pointerEvent);
        }

        if (boundingRect && data.eventName === 'pointerCalculated') {
          const pointerEvent: Pointer = {
            id: data.pointerCalculation.id,
            posX: data.pointerCalculation.posX + boundingRect.left,
            posY: data.pointerCalculation.posY + boundingRect.top,
            rx: data.pointerCalculation.rx,
            ry: data.pointerCalculation.ry,
            isOrganizer: data.pointerCalculation.isOrganizer,
            source: 'iframe',
          };
          showPointer(pointerEvent);
        }
      }
    };

    window.addEventListener('message', handleWindowMessage);

    return () => {
      window.removeEventListener('message', handleWindowMessage);
    };
  }, [showPointer, broadcastPointer]);

  useEffect(() => {
    const handleRemotePointerShown = (event: Pointer) => {
      const boundingRect = wrapperRef.current?.getBoundingClientRect();

      if (boundingRect) {
        if (event.source === 'overlay') {
          // Event dispatching from overlay (PDF)
          showPointer({
            id: event.id,
            posX: event.rx * boundingRect.width + boundingRect.left,
            posY: event.ry * boundingRect.height + boundingRect.top,
            isOrganizer: event.isOrganizer,
          } as Pointer);
        } else {
          iframe?.current?.contentWindow?.postMessage(
            {
              namespace: 'reveal',
              eventName: 'pointer',
              pointer: event,
            },
            '*'
          );
        }
      }
    };

    const unsubscribe = onPointerShown(handleRemotePointerShown);

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [iframe, onPointerShown, showPointer]);

  return (
    <Container>
      <Wrapper
        type={type}
        ref={wrapperRef}
        onMouseDown={(event) => handleMouseDown(event.clientX, event.clientY)}
        onMouseUp={handleMouseUp}
        onTouchStart={(event) => {
          const touch = event.touches[0] || event.changedTouches[0];
          handleMouseDown(touch.clientX, touch.clientY);
        }}
        onTouchEnd={handleMouseUp}
      >
        {pointers.map((i) => (
          <PointerAnimation
            key={i.id}
            x={i.posX - 50}
            y={i.posY - 50}
            isOrganizer={i.isOrganizer}
            callback={() => {
              setPointers((prevState) =>
                prevState.filter((it) => it.id !== i.id)
              );
            }}
          />
        ))}
        {children}
      </Wrapper>
    </Container>
  );
};

const Container = styled.div`
  display: grid;
  height: 100%;
  width: 100%;
`;

type WrapperStyleProps = {
  type: 'HTML' | 'PDF';
};

const Wrapper = styled.div<WrapperStyleProps>`
  align-self: center;
  justify-self: center;
  height: ${(props) => (props.type === 'HTML' ? '100%' : 'unset')};
  width: ${(props) => (props.type === 'HTML' ? '100%' : 'unset')};
`;
