import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { ReactComponent as SmileIcon } from '../../../assets/images/smile-face.svg';

export const PostFeedback: FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Wrapper>
      <SmileIcon data-testid="postfeedback-smile-icon" />
      <Text>{t('views.feedback.thanksForFeedback')}</Text>
      <StyledButton
        variant="outline"
        onClick={() => {
          navigate('/');
        }}
      >
        {t('views.feedback.close')}
      </StyledButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
`;

const Text = styled.p``;

const StyledButton = styled(Button)`
  border-color: ${(props) => props.theme.colors.gray};
  color: ${(props) => props.theme.colors.gray};

  :hover {
    background-color: transparent;
  }
`;
