import React, { FC, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { MediaPointer } from '../../../components/MediaPointer';

import { Media, MediaState, useComChannel } from '../../../services/ComChannel';

type HTMLViewProps = {
  media: Media;
};

export const HTMLView: FC<HTMLViewProps> = ({ media }) => {
  const container = useRef<HTMLDivElement>(null);
  const ref = useRef<HTMLIFrameElement>(null);
  const { broadcastMediaAttendeeReady, onMediaStateChanged } = useComChannel();

  const handleAttendeeReady = () => {
    if (media) {
      broadcastMediaAttendeeReady(media);
    }
  };

  useEffect(() => {
    const handlePresentationStateChanged = (data: MediaState) => {
      ref?.current?.contentWindow?.postMessage(data, '*');
    };

    const unsubscribeOnMediaStateChanged = onMediaStateChanged(
      handlePresentationStateChanged
    );

    return () => {
      if (unsubscribeOnMediaStateChanged) unsubscribeOnMediaStateChanged();
    };
  }, [onMediaStateChanged]);

  return (
    media && (
      <Wrapper ref={container}>
        <MediaPointer iframe={ref} type="HTML">
          <Iframe
            ref={ref}
            src={media.contentDocument.downloadUrl}
            title="HTML View"
            onLoad={handleAttendeeReady}
          />
        </MediaPointer>
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  height: 100%;
  width: 100%;
`;

const Iframe = styled.iframe`
  background-color: ${({ theme }): string => theme.colors.white};
  border: none;
  height: 100%;
  width: 100%;
  pointer-events: none; // Stop attendee to interact with the iframe which is the HTML View
`;
