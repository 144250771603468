import { hasKey } from '../../../services/helpers';
import { ParticipantProps } from '../../../services/Interfaces';

// we allow max 25 participants per session
const VIDEOS_PER_PAGE_DESKTOP = {
  1: [1, 2, 3, 4, 5, 6, 7, 8, 9],
  2: [10, 11, 12, 13, 14, 15, 16, 17, 18],
  3: [19, 20, 21, 22, 23, 24, 25],
};

const VIDEOS_PER_PAGE_MOBILE = {
  1: [1, 2, 3, 4, 5, 6, 7, 8],
  2: [9, 10, 11, 12, 13, 14, 15, 16],
  3: [17, 18, 19, 20, 21, 22, 23, 24],
  4: [25],
};

// >> Desktop view helpers
export const getGridTemplateColumns = (participantsCount: number): string => {
  if (participantsCount >= 2 && participantsCount <= 4) {
    return 'repeat(2, 1fr)';
  }

  if (participantsCount >= 5) {
    return 'repeat(3, 1fr)';
  }

  return 'repeat(1, 1fr)';
};

export const getGridTemplateRows = (currentPage: number): string => {
  // always show 3x3 grid when we're not on the 1st page
  if (currentPage > 1) {
    return 'repeat(3, 1fr)';
  }

  return '';
};

export const getParticipantsStyles = ({
  participantsCount,
  currentPage,
  pages,
}: ParticipantProps): string => {
  // if we have only 1 page, show custom styles (depending on the number of participants)
  if (pages === 1) {
    if (participantsCount === 1) {
      return getParticipant(1, 'center ');
    }

    if (participantsCount === 2) {
      return (
        getParticipant(1, 'center end') + getParticipant(2, 'center start')
      );
    }

    if (participantsCount === 3) {
      return (
        getParticipant(1, 'end') +
        getParticipant(2, 'end start') +
        getTransformedParticipant(3)
      );
    }

    if (participantsCount === 4) {
      return (
        getParticipant(1, 'end') +
        getParticipant(2, 'end start') +
        getParticipant(3, 'baseline end') +
        getParticipant(4, 'baseline start')
      );
    }

    if (participantsCount === 5) {
      return (
        getFullTopRow([1, 2, 3]) +
        getTransformedParticipant(4) +
        getTransformedParticipant(5)
      );
    }

    if (participantsCount === 6) {
      return getFullTopRow([1, 2, 3]) + getFullBottomRow([4, 5, 6]);
    }

    if (participantsCount === 7) {
      return (
        getFullTopRow([1, 2, 3]) +
        getFullMiddleRow([4, 5, 6]) +
        getParticipant(7, 'baseline end')
      );
    }

    if (participantsCount === 8) {
      return (
        getFullTopRow([1, 2, 3]) +
        getFullMiddleRow([4, 5, 6]) +
        getTransformedParticipant(7) +
        getTransformedParticipant(8)
      );
    }

    if (participantsCount === 9) {
      return (
        getFullTopRow([1, 2, 3]) +
        getFullMiddleRow([4, 5, 6]) +
        getFullBottomRow([7, 8, 9])
      );
    }
  }

  // if we have more than 1 page, show uniformed styles (for any number of participants)
  if (pages > 1) {
    return showCurrentPageParticipants(currentPage);
  }

  return '';
};

export const showCurrentPageParticipants = (currentPage: number): string => {
  let currentParticipants: number[] = [];

  if (hasKey(VIDEOS_PER_PAGE_DESKTOP, currentPage)) {
    currentParticipants = VIDEOS_PER_PAGE_DESKTOP[currentPage];
  }

  if (!currentParticipants.length) {
    return '';
  }

  const firstRowItems: number[] = currentParticipants.slice(0, 3);
  const secondRowItems: number[] = currentParticipants.slice(3, 6);
  const thirdRowItems: number[] = currentParticipants.slice(6, 9);

  return (
    getFullTopRow(firstRowItems) +
    getFullMiddleRow(secondRowItems) +
    getFullBottomRow(thirdRowItems)
  );
};

export const getFullTopRow = (videos: number[]): string => {
  const [video1, video2, video3] = videos;
  return (
    getParticipant(video1, 'end') +
    getParticipant(video2, 'end center') +
    getParticipant(video3, 'end start')
  );
};

export const getFullMiddleRow = (videos: number[]): string => {
  const [video1, video2, video3] = videos;
  return (
    getParticipant(video1, 'center end') +
    getParticipant(video2, 'center') +
    getParticipant(video3, 'center start')
  );
};

export const getFullBottomRow = (videos: number[]): string => {
  const [video1, video2, video3] = videos;
  return (
    getParticipant(video1, 'baseline end') +
    getParticipant(video2, 'baseline center') +
    getParticipant(video3, 'baseline start')
  );
};

// >> Mobile view helpers
export const getGridTemplateColumnsMobile = (
  participantsCount: number
): string => {
  if (participantsCount >= 3) {
    return 'repeat(2, 1fr)';
  }

  return 'repeat(1, 1fr)';
};

export const getGridTemplateRowsMobile = (currentPage: number): string => {
  // always show 4x2 grid when we're not on the 1st page
  if (currentPage > 1) {
    return 'repeat(4, 1fr)';
  }

  return '';
};

export const getParticipantsStylesMobile = ({
  participantsCount,
  currentPage,
  pages,
}: ParticipantProps): string => {
  // if we have only 1 page, show custom styles (depending on the number of participants)
  if (pages === 1) {
    if (participantsCount === 1) {
      return getParticipant(1, 'center');
    }

    if (participantsCount === 2) {
      return getParticipant(1, 'center') + getParticipant(2, 'center');
    }

    if (participantsCount === 3) {
      return getFullTopRowMobile([1, 2]) + getTransformedParticipant(3, true);
    }

    if (participantsCount === 4) {
      return getFullTopRowMobile([1, 2]) + getFullBottomRowMobile([3, 4]);
    }

    if (participantsCount === 5) {
      return (
        getFullTopRowMobile([1, 2]) +
        getFullMiddleRowMobile([3, 4]) +
        getTransformedParticipant(5, true)
      );
    }

    if (participantsCount === 6) {
      return (
        getFullTopRowMobile([1, 2]) +
        getFullMiddleRowMobile([3, 4]) +
        getFullBottomRowMobile([5, 6])
      );
    }

    if (participantsCount === 7) {
      return (
        getFullTopRowMobile([1, 2]) +
        getFullMiddleRowMobile([3, 4]) +
        getFullBottomRowMobile([5, 6]) +
        getTransformedParticipant(7, true)
      );
    }

    if (participantsCount === 8) {
      return (
        getFullTopRowMobile([1, 2]) +
        getFullMiddleRowMobile([3, 4]) +
        getFullMiddleRowMobile([5, 6]) +
        getFullBottomRowMobile([7, 8])
      );
    }
  }

  // if we have more than 1 page, show uniformed styles (for any number of participants)
  if (pages > 1) {
    return showCurrentPageParticipantsMobile(currentPage);
  }

  return '';
};

export const showCurrentPageParticipantsMobile = (
  currentPage: number
): string => {
  let currentParticipants: number[] = [];

  if (hasKey(VIDEOS_PER_PAGE_MOBILE, currentPage)) {
    currentParticipants = VIDEOS_PER_PAGE_MOBILE[currentPage];
  }

  if (!currentParticipants.length) {
    return '';
  }

  const firstRowItems: number[] = currentParticipants.slice(0, 2);
  const secondRowItems: number[] = currentParticipants.slice(2, 4);
  const thirdRowItems: number[] = currentParticipants.slice(4, 6);
  const fourthRowItems: number[] = currentParticipants.slice(6, 8);

  return (
    getFullTopRowMobile(firstRowItems) +
    getFullMiddleRowMobile(secondRowItems) +
    getFullMiddleRowMobile(thirdRowItems) +
    getFullBottomRowMobile(fourthRowItems)
  );
};

export const getFullTopRowMobile = (videos: number[]): string => {
  const [video1, video2] = videos;
  return getParticipant(video1, 'end') + getParticipant(video2, 'end start');
};

export const getFullMiddleRowMobile = (videos: number[]): string => {
  const [video1, video2] = videos;
  return (
    getParticipant(video1, 'center end') +
    getParticipant(video2, 'center start')
  );
};

export const getFullBottomRowMobile = (videos: number[]): string => {
  const [video1, video2] = videos;
  return (
    getParticipant(video1, 'baseline end') +
    getParticipant(video2, 'baseline start')
  );
};

// >> Mixed helpers
export const getTransformedParticipant = (
  type: number,
  mobile = false
): string => {
  return `.OT_subscriber:nth-of-type(${type}) {place-self:baseline end; transform:translateX(calc(50% + ${
    mobile ? '2px' : '8px'
  }));}`;
};

export const getParticipant = (type: number, placement: string): string => {
  return `.OT_subscriber:nth-of-type(${type}) {place-self: ${placement}};`;
};

// hide all participants except the ones belonging to the selected page
export const hideParticipants = ({
  participantsCount,
  currentPage,
  pages,
  mobile,
}: ParticipantProps): string => {
  // if no more than 9 participants, return nothing
  const maxVideosPerPage = mobile ? 8 : 9;
  if (participantsCount <= maxVideosPerPage) {
    return '';
  }

  const VIDEOS_PER_PAGE = mobile
    ? VIDEOS_PER_PAGE_MOBILE
    : VIDEOS_PER_PAGE_DESKTOP;

  const pageArray: number[] = Array.from({ length: pages }, (_, i) => i + 1);
  const pagesToHide: number[] = pageArray.filter(
    (page) => page !== currentPage
  );

  let videosToHide: number[] = [];
  for (let i = 0; i < pagesToHide.length; i++) {
    const currentPageToHide = hasKey(pagesToHide, i) ? pagesToHide[i] : null;
    if (!currentPageToHide) {
      break;
    }

    const currentPageVideos = hasKey(VIDEOS_PER_PAGE, currentPageToHide)
      ? VIDEOS_PER_PAGE[currentPageToHide]
      : [];

    videosToHide = [...videosToHide, ...currentPageVideos];
  }

  let videoParticipantsToHide = '';
  for (let i = 0; i < videosToHide.length; i++) {
    videoParticipantsToHide = `${videoParticipantsToHide} .OT_subscriber:nth-of-type(${videosToHide[i]}) {display: none};`;
  }

  return videoParticipantsToHide;
};
