import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { useNotifications, useRestApi } from '../../../hooks';
import { RatingStar } from './RatingStar';
import { RatingText } from './RatingText';

type FeedbackFormProps = {
  onFormSubmitted: () => void;
};

export const FeedbackForm: FC<FeedbackFormProps> = ({ onFormSubmitted }) => {
  const { t } = useTranslation();
  const { showWarningNotification } = useNotifications();
  const { roomId = '' } = useParams();
  const { addFeedback } = useRestApi();
  const navigate = useNavigate();

  const [isSubmitDisabled, setSubmitDisabled] = useState(true);
  const [isSubmitLoading, setSubmitLoading] = useState(false);
  const [note, setNote] = useState('');
  const [rating, setRating] = useState(0);

  const onChangeRating = useCallback((ratingValue: number) => {
    setRating(ratingValue);
    setSubmitDisabled(false);
  }, []);

  const handleCallRatingClick = () => {
    setSubmitDisabled(true);
    setSubmitLoading(true);

    addFeedback(roomId, rating, note)
      .then(() => {
        onFormSubmitted();
      })
      .catch(() => {
        showWarningNotification('submitError');
      })
      .finally(() => {
        setSubmitLoading(false);
      });
  };

  const handleMaybeLaterClick = () => {
    navigate('/');
  };

  return (
    <Wrapper>
      <Title>{t('views.feedback.title')}</Title>

      <RatingStar rating={rating} onChangeRating={onChangeRating} />

      <RatingText rating={rating} />

      {rating > 0 && (
        <Note
          value={note}
          data-testid="rating-textarea"
          onChange={(event) => {
            setNote(event.target.value);
          }}
          placeholder={t('views.feedback.leaveSomeMessage')}
        />
      )}

      <ButtonContainer>
        <Button
          onClick={handleCallRatingClick}
          disabled={isSubmitDisabled}
          isLoading={isSubmitLoading}
          variant="primary"
          customStyles={{
            width: '100%',
          }}
        >
          {t('views.feedback.rateNow')}
        </Button>
        <Button
          onClick={handleMaybeLaterClick}
          variant="greyGhost"
          customStyles={{
            padding: '0',
          }}
        >
          {t('views.feedback.maybeLater')}
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
`;

const Title = styled.h1`
  font-size: 20px;
  text-align: center;
`;

const Note = styled.textarea`
  border: none;
  border-radius: 8px;
  font-family: ${({ theme }) => theme.font.familyNormal};
  min-height: 120px;
  padding: 16px;
  resize: none;
  width: 100%;

  :focus {
    outline: none;
  }

  ::placeholder {
    color: ${(props) => props.theme.colors.gray};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  width: 100%;
`;
