import { useCallback } from 'react';
import { RoomState, useYroomState } from './useYroomState';

export type UseRoomState = RoomState & {
  setRoomState: (room: Partial<RoomState>) => void;
};

export const useRoomState = (): UseRoomState => {
  const { yroomState, setYroomState } = useYroomState();

  const setRoomState = useCallback(
    (room: Partial<RoomState>) => {
      setYroomState((oldState) => {
        const newRoomState = {
          ...oldState.room,
          ...room,
        };

        return {
          ...oldState,
          room: newRoomState,
        };
      });
    },
    [setYroomState]
  );

  return {
    ...yroomState.room,
    setRoomState,
  };
};
