import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import { MediaPointer } from '../../../components/MediaPointer';

import { Media, MediaState, useComChannel } from '../../../services/ComChannel';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { useContainerSize } from '../useContainerSize';

pdfjs.GlobalWorkerOptions.workerSrc = '/lib/pdf.worker.min.js';

type PDFViewProps = {
  media: Media;
};

export const PDFView: FC<PDFViewProps> = ({ media }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const container = useRef<HTMLDivElement>(null);
  const { broadcastMediaAttendeeReady, onMediaStateChanged } = useComChannel();
  const containerSize = useContainerSize(container);
  const pdfSize = useMemo(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      return { width: containerSize.width };
    }

    return { height: containerSize.height };
  }, [containerSize.height, containerSize.width]);

  const handleAttendeeReady = useCallback(() => {
    if (media) {
      broadcastMediaAttendeeReady(media);
    }
  }, [broadcastMediaAttendeeReady, media]);

  useEffect(() => {
    const handlePresentationStateChanged = (data: MediaState) => {
      if (data?.state?.page) setPageNumber(data.state.page);
    };

    const unsubscribeOnMediaStateChanged = onMediaStateChanged(
      handlePresentationStateChanged
    );

    return () => {
      if (unsubscribeOnMediaStateChanged) unsubscribeOnMediaStateChanged();
    };
  }, [onMediaStateChanged]);

  return (
    media && (
      <Wrapper ref={container}>
        <MediaPointer type="PDF">
          <Document
            file={media.contentDocument.downloadUrl}
            options={{ isEvalSupported: false }}
          >
            <Page
              {...pdfSize}
              pageNumber={pageNumber}
              renderAnnotationLayer={false}
              renderTextLayer={false}
              onRenderSuccess={handleAttendeeReady}
            />
          </Document>
        </MediaPointer>
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-grow: 1;
  margin: auto;
  text-align: center;
  width: 100%;
  overflow: auto;

  canvas {
    margin: auto;
  }
`;
