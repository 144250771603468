export enum VideoModes {
  ownVideo = 'ownVideo',
  participantsVideo = 'participantsVideo',
}

export enum NotificationLabels {
  attendeeJoined = 'ysura-video.attendeeJoined',
  attendeeLeft = 'ysura-video.attendeeLeft',
  organizerJoined = 'ysura-video.organizerJoined',
  organizerLeft = 'ysura-video.organizerLeft',
  connected = 'ysura-video.connected',
  error = 'ysura-video.tryingToConnect',
}

export enum NotificationTypes {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

export enum YsuraVideoEvents {
  // Events for attendee-ui <-> web-components communication
  disconnected = 'ysura-video:room:disconnected',
  connected = 'ysura-video:room:connected',
  failedToConnect = 'ysura-video:room:failedToConnect',
  error = 'ysura-video:room:error',
  organizerNotification = 'ysura-video:room:organizerNotification',
  toggleCamera = 'ysura-video:camera:toggle',
  toggleMic = 'ysura-video:mic:toggle',

  // Events for web-components <-> web-components communication
  roomDataCreated = 'ysura-video:room:roomDataCreated',
}
