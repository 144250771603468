import {
  Camera,
  CameraOff,
  Mic,
  MicOff,
  Wifi,
  Repeat,
  Monitor,
  Video,
  Loader,
} from 'react-feather';

export enum IconTypes {
  camera = 'camera',
  cameraOff = 'cameraOff',
  cycleCamera = 'cycleCamera',
  loader = 'loader',
  mic = 'mic',
  connection = 'connection',
  micOff = 'micOff',
  screenShare = 'screenShare',
  video = 'video',
}

export interface IconProps {
  type: IconTypes;
  color: string;
  size?: number;
}

const icons = {
  camera: Camera,
  cameraOff: CameraOff,
  cycleCamera: Repeat,
  loader: Loader,
  mic: Mic,
  micOff: MicOff,
  connection: Wifi,
  screenShare: Monitor,
  video: Video,
};

export const Icon: React.FC<IconProps> = ({ type, color, size = 24 }) => {
  const SelectedIcon = icons[IconTypes[type]];
  return (
    <SelectedIcon
      color={color}
      size={size}
      data-testid={`icon-${IconTypes[type]}`}
    />
  );
};
