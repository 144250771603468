const USER_SETTINGS = 'userSettings';

export interface UserPreferences {
  video?: VideoPreferences;
}

export interface VideoPreferences {
  fitMode?: 'cover' | 'contain' | undefined;
}

interface UserPreferencesHook {
  getUserPreferences: () => UserPreferences;
  saveUserPreferences: (preferences: UserPreferences) => void;
}

export const useUserPreferences = (): UserPreferencesHook => {
  const saveUserPreferences = (preferences: UserPreferences): void => {
    localStorage.setItem(USER_SETTINGS, JSON.stringify(preferences));
  };

  const getUserPreferences = (): UserPreferences => {
    return JSON.parse(localStorage.getItem(USER_SETTINGS) || '{}');
  };

  return {
    saveUserPreferences,
    getUserPreferences,
  };
};
