import { FC, useCallback, useMemo, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { useRoomState, useRestApi } from '../../../hooks';
import { featureEnabled, features } from '../../../services/FeatureFlag';
import { Container } from '../Container';
import { FormInput } from './FormInput';

type FormState = {
  roomCode: string;
  name: string;
  email: string;
  invalidCode: boolean;
  invalidName: boolean;
  invalidEmail: boolean;
  isShaking: boolean;
  isLoading: boolean;
};

const initialState = {
  roomCode: '',
  name: '',
  email: '',
  invalidCode: false,
  invalidName: false,
  invalidEmail: false,
  isShaking: false,
  isLoading: false,
};

export const JoinRoomForm: FC = () => {
  const isNewAttendeeMgmtAvailable = featureEnabled(features.NEW_ATTENDEE_MGMT);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [
    {
      roomCode,
      name,
      email,
      invalidCode,
      invalidName,
      invalidEmail,
      isShaking,
      isLoading,
    },
    setState,
  ] = useReducer(
    (currentState: FormState, newState: Partial<FormState>) => ({
      ...currentState,
      ...newState,
    }),
    {
      ...initialState,
      roomCode: searchParams.get('roomCode') || '',
      name: searchParams.get('name') || '',
      email: searchParams.get('email') || '',
    }
  );
  const onError = useCallback(() => {
    setState({
      invalidCode: true,
      invalidName: !name,
      invalidEmail: !email,
      isShaking: true,
    });
    setTimeout(() => {
      setState({ isShaking: false });
    }, 1500);
  }, [email, name]);

  const { getRoom } = useRestApi();
  const { setRoomState } = useRoomState();
  const onJoinRoom = useCallback(async () => {
    if (!roomCode || (isNewAttendeeMgmtAvailable && (!name || !email))) {
      onError();
    } else {
      setState({ isLoading: true });
      getRoom(roomCode)
        .then((room) => {
          const roomId = room.id;
          if (room && room.id) {
            setRoomState({
              roomId,
              name,
              email,
            });
            if (isNewAttendeeMgmtAvailable) {
              navigate(`/room/${roomId}?name=${name}&email=${email}`);
            } else {
              navigate(`/room/${roomId}`);
            }
          }
        })
        .catch(() => {
          onError();
        })
        .finally(() => {
          setState({ isLoading: false });
        });
    }
  }, [
    email,
    getRoom,
    isNewAttendeeMgmtAvailable,
    name,
    navigate,
    onError,
    roomCode,
    setRoomState,
  ]);

  const isReadyToJoin = useMemo(() => {
    if (!isNewAttendeeMgmtAvailable) {
      return !!roomCode;
    }
    return !!(roomCode && name && email);
  }, [email, isNewAttendeeMgmtAvailable, name, roomCode]);

  return (
    <Container>
      <FormInput
        title="views.preCallTest.roomCode"
        label="views.preCallTest.accessCode"
        instruction="views.preCallTest.typeAccessCode"
        testId={`textbox-roomcode${invalidCode ? '-invalid' : ''}`}
        isAnimate={invalidCode && isShaking}
        isInvalid={invalidCode}
        value={roomCode}
        onChange={(event) => setState({ roomCode: event.target.value })}
        onJoinRoom={onJoinRoom}
      />
      {isNewAttendeeMgmtAvailable && (
        <>
          <FormInput
            title="views.preCallTest.name"
            label="views.preCallTest.name"
            isAnimate={invalidName && isShaking}
            isInvalid={invalidName}
            value={name}
            onChange={(event) => setState({ name: event.target.value })}
            onJoinRoom={onJoinRoom}
          />

          <FormInput
            title="views.preCallTest.email"
            label="views.preCallTest.email"
            isAnimate={invalidEmail && isShaking}
            isInvalid={invalidEmail}
            value={email}
            onChange={(event) => setState({ email: event.target.value })}
            onJoinRoom={onJoinRoom}
          />
        </>
      )}
      <ButtonContainer>
        <Button
          disabled={!isReadyToJoin}
          isLoading={isLoading}
          variant="primary"
          onClick={onJoinRoom}
        >
          {t('views.preCallTest.joinRoom')}
        </Button>
      </ButtonContainer>
    </Container>
  );
};

const ButtonContainer = styled.div`
  margin: auto;
  width: fit-content;
`;
