import { OpenTokSession } from './OpenTok/OpenTokSession';
import { OpenViduSession } from './OpenVidu/OpenViduSession';
import { YsVideoProviderSession } from './YsVideoProviderSession';

export interface Config {
  apiKey: string;
  sessionId: string;
  token: string;
  rtcProvider: 'TOKBOX' | 'OPENVIDU';
}

interface Providers {
  TOKBOX(config: Config): YsVideoProviderSession;
  OPENVIDU(): YsVideoProviderSession;
}

const providerSessionOptions: Providers = {
  TOKBOX: (config: Config) =>
    new OpenTokSession(config.apiKey, config.sessionId),
  OPENVIDU: () => new OpenViduSession(),
};

export const initSession = (
  config: Config
): Promise<YsVideoProviderSession> => {
  if (config.rtcProvider === 'TOKBOX') {
    return Promise.resolve(providerSessionOptions.TOKBOX(config));
  }

  return Promise.resolve(providerSessionOptions.OPENVIDU());
};
