import { DefaultTheme } from 'styled-components';
import { hasKey } from '../../services/helpers';

type ParticipantProps = {
  participantNumber: number;
  theme: DefaultTheme;
};

type VideoProps = {
  isSharing?: boolean;
  isFullScreen?: boolean;
};

export const getVideoWrapperHeight = ({
  isSharing,
  isFullScreen,
}: VideoProps): string => {
  if (isFullScreen) {
    return '100%';
  }

  // wrapper height = 100% - header height - videoButtons height
  if (isSharing && !isFullScreen) {
    return 'calc(100% - 40px - 72px)';
  }

  return 'calc(100% - 40px - 80px)';
};

export const getParticipantBackgroundColor = ({
  theme,
  participantNumber,
}: ParticipantProps): string => {
  if (hasKey(theme.palette, participantNumber)) {
    return theme.palette[participantNumber];
  }

  const randomColorKey = Math.floor(Math.random() * 5) + 1;
  if (hasKey(theme.palette, randomColorKey)) {
    return theme.palette[randomColorKey];
  }

  return '';
};

export const getOwnVideoHeight = ({
  isSharing,
  isFullScreen,
}: VideoProps): string => {
  if (isFullScreen) {
    return 'unset';
  }

  if (isSharing) {
    return '20%';
  }

  return '100%';
};

export const getOwnVideoWidth = ({
  isSharing,
  isFullScreen,
}: VideoProps): string => {
  if (isFullScreen) {
    return 'unset';
  }

  if (isSharing) {
    return 'calc(50% - 4px)';
  }

  return '25%';
};
