import React, { useCallback, useContext, useMemo, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { NotificationLabels, NotificationTypes } from '../Enums';
import { YsuraVideoEvents } from '../../enums';
import { YsVideoProviderSession, initSession } from '../rtcClient';
import { getStateServerUrl } from '../../helpers';
import { useSharingState, useRoomState } from '../../hooks';

export interface AttendeeComChannelProviderInterface {
  isComChannelInitialized: boolean;
  roomInfo: RoomInfo;
  initChannel: ({ roomId, roomCode }: InitChannelArgs) => void;
  setRoomMetaData: (metaData: RoomMetaData) => void;
  onSessionExpired: (callback: Callback) => Callback;
  onOrganizerLoggedOut: (callback: Callback) => Callback;
  onOrganizerTerminateSession: (callback: Callback) => Callback;
  onRoomMetaData: (callback: Callback) => Callback;
  onAttendeeReadyOrganizerNotify: (callback: Callback) => Callback;
  onFormOpened: (callback: DataCallback<Form>) => Callback;
  onFormClosed: (callback: Callback) => Callback;
  onFormValueChanged: (callback: DataCallback<FormInput>) => Callback;
  onMediaOpened: (callback: DataCallback<Media>) => Callback;
  onMediaClosed: (callback: Callback) => Callback;
  onMediaStateChanged: (callback: DataCallback<MediaState>) => Callback;
  onPointerShown: (callback: DataCallback<Pointer>) => Callback;
  broadcastDisconnectClient: () => void;
  broadcastRoomMetaData: (event: unknown) => void;
  broadcastFormValueChange: (event: unknown) => void;
  broadcastMediaAttendeeReady: (event: Media) => void;
  broadcastFormCancel: Callback;
  broadcastFormSubmit: DataCallback<FormInput>;
  broadcastPointerShow: (event: Pointer) => void;
  attendeeReady: (event: unknown) => void;
  leaveCall: () => void;
  videoSession?: YsVideoProviderSession;
}

export interface InitChannelArgs {
  roomId?: string | undefined;
  roomCode?: string | undefined;
}

export interface RoomMetaData {
  organizer: {
    streamId: string;
  };
  screenShareEnabled: boolean;
}

export interface RoomInfo {
  connected?: boolean;
  sessionId?: string;
  roomMetaData?: RoomMetaData;
  limitExceeded?: boolean;
  token?: string;
  apiKey?: string;
  rtcProvider?: 'TOKBOX' | 'OPENVIDU';
}

export interface Form {
  html: string;
  name: string;
}

export interface Media {
  contentDocument: {
    downloadUrl: string;
    mediaType: string;
  };
}

export interface MediaState {
  state: {
    page: number;
  };
}

export interface FormInput {
  valid: boolean;
}

export interface Pointer {
  id: string;
  posX: number;
  posY: number;
  rx: number;
  ry: number;
  isOrganizer: boolean;
  source: 'iframe' | 'overlay';
}

interface AttendeeComChannelProps {
  children: JSX.Element;
}

interface EventSubscription {
  name: string;
  callback: Callback;
}

type Callback = () => void;
type DataCallback<T> = (data: T) => void;

const DEFAULT_CALLBACK = (): void => {};
const ROLE = 'attendee';
const WS_URL = getStateServerUrl() || 'http://localhost:3010';

export const AttendeeComChannelContext =
  React.createContext<AttendeeComChannelProviderInterface>({
    isComChannelInitialized: false,
    roomInfo: {},
    initChannel: () => {},
    setRoomMetaData: () => {},
    onSessionExpired: () => DEFAULT_CALLBACK,
    onOrganizerLoggedOut: () => DEFAULT_CALLBACK,
    onOrganizerTerminateSession: () => DEFAULT_CALLBACK,
    onRoomMetaData: () => DEFAULT_CALLBACK,
    onAttendeeReadyOrganizerNotify: () => DEFAULT_CALLBACK,
    onFormOpened: () => DEFAULT_CALLBACK,
    onFormClosed: () => DEFAULT_CALLBACK,
    onFormValueChanged: () => DEFAULT_CALLBACK,
    onMediaOpened: () => DEFAULT_CALLBACK,
    onMediaClosed: () => DEFAULT_CALLBACK,
    onMediaStateChanged: () => DEFAULT_CALLBACK,
    onPointerShown: () => DEFAULT_CALLBACK,
    broadcastDisconnectClient: () => {},
    broadcastRoomMetaData: () => {},
    broadcastFormValueChange: () => {},
    broadcastFormCancel: () => {},
    broadcastFormSubmit: () => {},
    broadcastMediaAttendeeReady: () => {},
    broadcastPointerShow: () => {},
    attendeeReady: () => {},
    leaveCall: () => {},
    videoSession: undefined,
  });

export const useComChannel = (): AttendeeComChannelProviderInterface => {
  return useContext(AttendeeComChannelContext);
};

export const AttendeeComChannelProvider = ({
  children,
}: AttendeeComChannelProps): JSX.Element => {
  const [socket, setSocket] = useState<Socket | null>(null);
  const [roomInfo, setRoomInfo] = useState<RoomInfo>({});
  const [isComChannelInitialized, setIsComChannelInitialized] = useState(false);
  const [videoSession, setVideoSession] = useState<
    YsVideoProviderSession | undefined
  >(undefined);

  const { setSharingState } = useSharingState();
  const { setRoomState } = useRoomState();

  const setRoomMetaData = (metaData: RoomMetaData): void => {
    setRoomInfo((oldState) => {
      return {
        ...oldState,
        roomMetaData: metaData,
      };
    });
  };

  const initializeVideoSession = useCallback((roomData: RoomInfo) => {
    const { apiKey = '', token, sessionId, rtcProvider } = roomData;

    // TOKBOX needs an apiKey, OPENVIDU does not
    if (rtcProvider === 'TOKBOX' && !apiKey) {
      return;
    }

    if (token && sessionId && rtcProvider) {
      initSession({
        apiKey,
        token,
        sessionId,
        rtcProvider,
      }).then((session) => {
        setVideoSession(session);
        // TODO: how to do error handling?
      });
    }
  }, []);

  const initChannel = useCallback(
    ({ roomId, roomCode }: InitChannelArgs): void => {
      const soc = io(`${WS_URL}`, {
        transports: ['websocket'],
        upgrade: false,
      });

      let isVideoInitialized = false;
      soc.on('room_joined', (data: RoomInfo) => {
        if (data.limitExceeded) {
          const logoutEvent = new CustomEvent(YsuraVideoEvents.disconnected, {
            detail: {
              notification: {
                message: 'r800',
                type: NotificationTypes.error,
              },
            },
          });
          window.dispatchEvent(logoutEvent);
          soc.disconnect();

          return;
        }

        const roomJoinedEvent = new CustomEvent(YsuraVideoEvents.connected, {
          detail: {
            notification: {
              message: NotificationLabels.connected,
              type: NotificationTypes.success,
            },
          },
        });
        window.dispatchEvent(roomJoinedEvent);

        if (!isVideoInitialized) {
          initializeVideoSession(data);
          isVideoInitialized = true;
        }

        setRoomInfo(data);
      });

      soc.on('organizer_connected', (data: RoomInfo) => {
        const { connected } = data;
        const message = connected
          ? NotificationLabels.organizerJoined
          : NotificationLabels.organizerLeft;

        const organizerConnectedEvent = new CustomEvent(
          YsuraVideoEvents.organizerNotification,
          {
            detail: {
              notification: {
                message,
                type: connected
                  ? NotificationTypes.success
                  : NotificationTypes.warning,
              },
            },
          }
        );

        window.dispatchEvent(organizerConnectedEvent);
        setRoomInfo(data);
      });

      soc.on('connect_error', () => {
        const connectionFailedEvent = new CustomEvent(
          YsuraVideoEvents.failedToConnect,
          {
            detail: {
              notification: {
                message: NotificationLabels.error,
                type: NotificationTypes.error,
              },
            },
          }
        );

        window.dispatchEvent(connectionFailedEvent);
      });

      soc.on('connect', () => {
        soc.emit('room_join', {
          role: ROLE,
          roomId,
          roomCode,
          // TODO: check feature flag if restricted is true or fals
          restricted: false,
        });
      });

      setSocket(() => {
        setIsComChannelInitialized(true);

        return soc;
      });
    },
    [initializeVideoSession]
  );

  const subscribeToEvent = useCallback(
    ({ name, callback }: EventSubscription): Callback => {
      if (socket) {
        socket.on(name, callback);
      }

      return (): void => {
        if (socket) {
          socket.removeListener(name, callback);
        }
      };
    },
    [socket]
  );

  const emit = useCallback(
    (eventName: string, event: unknown): void => {
      if (socket) {
        socket.emit(eventName, event);
      }
    },
    [socket]
  );

  const onSessionExpired = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({ name: 'session_expired', callback });
    },
    [subscribeToEvent]
  );

  const onOrganizerLoggedOut = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'organizer_logged_out',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onOrganizerTerminateSession = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'organizer_do_client_disconnected',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onRoomMetaData = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'room_meta_data',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onAttendeeReadyOrganizerNotify = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'attendee_ready_organizer_notified',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onFormOpened = useCallback(
    (callback: DataCallback<Form>): Callback => {
      if (socket) {
        socket.on('organizer_form_opened', callback);
      }

      return (): void => {
        if (socket) {
          socket.removeListener('organizer_form_opened', callback);
        }
      };
    },
    [socket]
  );

  const onFormClosed = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'organizer_form_closed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onFormValueChanged = useCallback(
    (callback: DataCallback<FormInput>): Callback => {
      if (socket) {
        socket.on('form_viewer_value_changed', callback);
      }

      return (): void => {
        if (socket) {
          socket.removeListener('form_viewer_value_changed', callback);
        }
      };
    },
    [socket]
  );

  const onMediaOpened = useCallback(
    (callback: DataCallback<Media>): Callback => {
      if (socket) {
        socket.on('organizer_media_opened', callback);
      }

      return (): void => {
        if (socket) {
          socket.removeListener('organizer_media_opened', callback);
        }
      };
    },
    [socket]
  );

  const onMediaClosed = useCallback(
    (callback: Callback): Callback => {
      return subscribeToEvent({
        name: 'organizer_media_closed',
        callback,
      });
    },
    [subscribeToEvent]
  );

  const onMediaStateChanged = useCallback(
    (callback: DataCallback<MediaState>): Callback => {
      if (socket) {
        socket.on('organizer_presentation_state_changed', callback);
      }

      return (): void => {
        if (socket) {
          socket.removeListener(
            'organizer_presentation_state_changed',
            callback
          );
        }
      };
    },
    [socket]
  );

  const onPointerShown = useCallback(
    (callback: DataCallback<Pointer>): Callback => {
      if (socket) {
        socket.on('pointer_shown', callback);
      }

      return (): void => {
        if (socket) {
          socket.removeListener('pointer_shown', callback);
        }
      };
    },
    [socket]
  );

  const broadcastDisconnectClient = useCallback((): void => {
    emit('organizer_do_client_disconnect', {});
  }, [emit]);

  const broadcastRoomMetaData = useCallback(
    (event: unknown): void => {
      emit('room_meta_data', event);
    },
    [emit]
  );

  const broadcastFormValueChange = useCallback(
    (event: unknown): void => {
      emit('form_viewer_value_change', event);
    },
    [emit]
  );

  const broadcastFormSubmit = useCallback(
    (data: FormInput): void => {
      emit('attendee_form_submit', data);
    },
    [emit]
  );

  const broadcastMediaAttendeeReady = useCallback(
    (data: Media): void => {
      emit('attendee_ready_for_media', data);
    },
    [emit]
  );

  const broadcastFormCancel = useCallback((): void => {
    emit('attendee_form_cancel', {});
  }, [emit]);

  const attendeeReady = useCallback(
    (event: unknown): void => {
      emit('attendee_ready_for_media', event);
    },
    [emit]
  );

  const broadcastPointerShow = useCallback(
    (event: Pointer): void => {
      emit('pointer_show', event);
    },
    [emit]
  );

  const leaveCall = useCallback((): void => {
    socket?.removeAllListeners();
    socket?.disconnect();
    videoSession?.disconnect();

    setIsComChannelInitialized(false);
    setVideoSession(undefined);
    setRoomInfo({});

    setSharingState({
      isFormSharing: false,
      isMediaSharing: false,
      isOwnScreenShared: false,
      isParticipantsScreenShared: false,
      isFullScreen: false,
    });

    setRoomState({
      roomId: '',
      name: '',
      email: '',
    });
  }, [setRoomState, setSharingState, socket, videoSession]);

  const contextValue = useMemo(
    () => ({
      isComChannelInitialized,
      roomInfo,
      initChannel,
      setRoomMetaData,
      onSessionExpired,
      onOrganizerLoggedOut,
      onOrganizerTerminateSession,
      onRoomMetaData,
      onAttendeeReadyOrganizerNotify,
      onFormOpened,
      onFormClosed,
      onFormValueChanged,
      onMediaOpened,
      onMediaClosed,
      onMediaStateChanged,
      onPointerShown,
      broadcastDisconnectClient,
      broadcastRoomMetaData,
      broadcastFormValueChange,
      broadcastFormCancel,
      broadcastFormSubmit,
      broadcastMediaAttendeeReady,
      broadcastPointerShow,
      attendeeReady,
      leaveCall,
      videoSession,
    }),
    [
      isComChannelInitialized,
      roomInfo,
      initChannel,
      onSessionExpired,
      onOrganizerLoggedOut,
      onOrganizerTerminateSession,
      onRoomMetaData,
      onAttendeeReadyOrganizerNotify,
      onFormOpened,
      onFormClosed,
      onFormValueChanged,
      onMediaOpened,
      onMediaClosed,
      onMediaStateChanged,
      onPointerShown,
      broadcastDisconnectClient,
      broadcastRoomMetaData,
      broadcastFormValueChange,
      broadcastFormCancel,
      broadcastFormSubmit,
      broadcastMediaAttendeeReady,
      broadcastPointerShow,
      attendeeReady,
      leaveCall,
      videoSession,
    ]
  );

  return (
    <AttendeeComChannelContext.Provider value={contextValue}>
      {children}
    </AttendeeComChannelContext.Provider>
  );
};
