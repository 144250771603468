import { useEffect, useState, SetStateAction, Dispatch } from 'react';

export type LocalStorageValue = boolean | string | number;
export type LocalStorageStateSetter = Dispatch<
  SetStateAction<LocalStorageValue>
>;
export type LocalStorageStateHook = [
  LocalStorageValue,
  LocalStorageStateSetter
];
export type KeyOptions = 'isYroomVideoEnabled' | 'isYroomMicEnabled';

export const useLocalStorageState = (
  key: KeyOptions,
  defaultValue: LocalStorageValue = ''
): LocalStorageStateHook => {
  const [state, setState] = useState(() => {
    const valueInLocalStorage = window.localStorage.getItem(key);
    if (valueInLocalStorage) {
      return JSON.parse(valueInLocalStorage);
    }

    return defaultValue;
  });

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
