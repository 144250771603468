import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';

import { HTMLView } from './HTMLView';
import { PDFView } from './PDFView';
import { useSharingState } from '../../hooks';
import { Media, useComChannel } from '../../services/ComChannel';

export const MediaView: FC = () => {
  const [media, setMedia] = useState<Media | null>(null);
  const { setSharingState } = useSharingState();
  const { onMediaOpened, onMediaClosed } = useComChannel();

  useEffect(() => {
    const handleShowMedia = (data: Media) => {
      setSharingState({ isMediaSharing: true });
      setMedia(data);
    };

    const handleHideMedia = () => {
      // Ensure that fullScreen is off when MediaView goes away
      setSharingState({ isMediaSharing: false, isFullScreen: false });
      setMedia(null);
    };

    const unsubscribeOnMediaOpen = onMediaOpened(handleShowMedia);
    const unsubscribeOnMediaClose = onMediaClosed(handleHideMedia);

    return () => {
      if (unsubscribeOnMediaOpen) unsubscribeOnMediaOpen();
      if (unsubscribeOnMediaClose) unsubscribeOnMediaClose();
    };
  }, [onMediaClosed, onMediaOpened, setSharingState]);

  return (
    media && (
      <Wrapper>
        {media.contentDocument.mediaType === 'text/html' && (
          <HTMLView media={media} />
        )}
        {media.contentDocument.mediaType === 'application/pdf' && (
          <PDFView media={media} />
        )}
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.almostBlack};
  display: flex;
  flex-direction: column;
  height: 100%;
`;
