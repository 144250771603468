import { ChangeEvent, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { css, keyframes } from 'styled-components';

type FormInputProps = {
  label: string;
  instruction?: string;
  title: string;
  testId?: string;
  isAnimate: boolean;
  isInvalid: boolean;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onJoinRoom: () => void;
};

export const FormInput: FC<FormInputProps> = ({
  label,
  instruction,
  title,
  testId,
  isAnimate,
  isInvalid,
  value,
  onChange,
  onJoinRoom,
}) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Label>{t(label)}</Label>
      {instruction && <Instruction>{t(instruction)}</Instruction>}
      <Input
        title={t(title)}
        data-testid={testId}
        isAnimate={isAnimate}
        isInvalid={isInvalid}
        value={value}
        onChange={onChange}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onJoinRoom();
          }
        }}
      />
    </Wrapper>
  );
};

FormInput.defaultProps = {
  instruction: '',
  testId: '',
};

const Wrapper = styled.div``;

const Label = styled.div`
  font-weight: bold;
  line-height: 18px;
  text-align: center;
`;

const Instruction = styled.div`
  line-height: 18px;
  text-align: center;
`;

const shake = keyframes`
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const Input = styled.input<{ isAnimate: boolean; isInvalid: boolean }>`
  border: ${({ isInvalid, theme }) =>
    isInvalid ? `1px solid ${theme.colors.error}` : '1px solid transparent'};
  border-radius: 8px;
  font-size: 16px;
  height: 40px;
  margin: 16px 0;
  padding: 0 16px;
  width: 100%;
  animation: ${({ isAnimate }) =>
    isAnimate
      ? css`
          ${shake} 0.3s cubic-bezier(0.36, 0.07, 0.19, 0.97) both
        `
      : 'none'};

  :focus-visible {
    border: ${({ isInvalid, theme }) =>
      isInvalid
        ? `1px solid ${theme.colors.error}`
        : `1px solid ${theme.colors.focus}`};
    box-shadow: inset 0 0 4px ${({ theme }) => theme.colors.boxShadow};
    outline: none;
  }
`;
