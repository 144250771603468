import styled from 'styled-components';

export const Footer: React.FC = () => {
  return (
    <Wrapper>
      <Line>ysura GmbH • Metzstr. 14b • 81667 München</Line>
      <Line>www.ysura.com</Line>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 56px;
`;

const Line = styled.div`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
`;
