import { useEffect, useState, forwardRef } from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import MicOffIcon from '../../../assets/images/micOffIcon.svg';
import { PaginationButton } from './PaginationButton';
import { ParticipantProps } from '../../../services/Interfaces';
import {
  getGridTemplateColumns,
  getGridTemplateRows,
  getParticipantsStyles,
  hideParticipants,
  getGridTemplateColumnsMobile,
  getGridTemplateRowsMobile,
  getParticipantsStylesMobile,
} from './participantsLayoutHelper';
import { LoadingIcon } from '../../../components/LoadingIcon';

interface ParticipantsVideoProps {
  participants?: number;
}

export const ParticipantsVideo = forwardRef<
  HTMLDivElement,
  ParticipantsVideoProps
>(({ participants = 0 }, ref) => {
  const [pages, setPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const theme = useTheme();
  const { t } = useTranslation();

  useEffect(() => {
    const breakpoint = parseInt(
      theme.breakpoints.mobileMaxWidth?.split('px')[0],
      10
    );
    const maxNumberOfParticipantsPerPage =
      window.innerWidth <= breakpoint ? 8 : 9;

    const calculatePageSize = (count: number): number => {
      return Math.ceil(count / maxNumberOfParticipantsPerPage);
    };

    if (participants) {
      setPages(calculatePageSize(participants));
    }
  }, [participants, theme]);

  useEffect(() => {
    const participantList = document.querySelectorAll('.OT_subscriber');

    participantList.forEach((participant, i) => {
      // first 5 participants should follow the palette order; from the 6th, we assign random palette color
      const videoOrder = i + 1 < 6 ? i + 1 : Math.floor(Math.random() * 5) + 1;
      const backgroundColorClass = `no-video-participant-${videoOrder}`;

      // remove class if already exists, so we can keep the correct color order
      const existingBackgroundColorClass =
        participant?.children[0]?.classList[1];

      if (existingBackgroundColorClass) {
        participant?.children[0]?.classList.remove(
          existingBackgroundColorClass
        );
      }

      participant?.children[0]?.classList.add(backgroundColorClass);
    });
  }, [participants]);

  const handleChangePage = (type: string): void => {
    if (type === 'decrement' && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }

    if (type === 'increment' && currentPage < pages) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Wrapper>
      <Participants
        data-testid="ysura-video-participantsVideo"
        ref={ref}
        participantsCount={participants}
        currentPage={currentPage}
        pages={pages}
        micOffIconPath={MicOffIcon}
      >
        {!participants && (
          <LoadingMessage>
            <LoadingIcon size={40} />
            <p>{t('views.room.waitingForOthers')}</p>
          </LoadingMessage>
        )}
        {pages > 1 && currentPage > 1 && (
          <PaginationButton
            paginationButtonType="back"
            onClick={(): void => handleChangePage('decrement')}
            testId="backButton"
          />
        )}
        {pages > 1 && currentPage !== pages && (
          <PaginationButton
            paginationButtonType="forward"
            onClick={(): void => handleChangePage('increment')}
            testId="forwardButton"
          />
        )}
      </Participants>
    </Wrapper>
  );
});

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

// TODO: load media queries values from the theme
const Participants = styled.div<ParticipantProps>`
  display: grid;
  grid-gap: 16px;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 32px;
  position: relative;

  // if video exist, set the active audio border directly on the video element
  .video-subscriber-audio-active {
    video {
      border: 4px solid ${({ theme }) => theme.colors.white};
    }

    .OT_widget-container {
      border: none;
    }
  }

  // if video exist, set the active audio border on the container
  .OT_audio-only.video-subscriber-audio-active {
    video {
      border: none;
    }

    .OT_widget-container {
      border: 4px solid ${({ theme }) => theme.colors.white};
    }
  }

  // apply background color only if OT_audio-only class exists
  .OT_audio-only {
    .no-video-participant-1 {
      background-color: ${({ theme }) => theme.palette[1]};
    }

    .no-video-participant-2 {
      background-color: ${({ theme }) => theme.palette[2]};
    }

    .no-video-participant-3 {
      background-color: ${({ theme }) => theme.palette[3]};
    }

    .no-video-participant-4 {
      background-color: ${({ theme }) => theme.palette[4]};
    }

    .no-video-participant-5 {
      background-color: ${({ theme }) => theme.palette[5]};
    }
  }

  // TODO: check if we can get rid of z-index
  // if audio-indicator-off class exists, show mic off icon
  .audio-indicator-off {
    .icon-mic-off-container {
      z-index: 10;
      display: flex !important;
      align-items: center;
      justify-content: center;
      border-radius: 8px;
      position: absolute;
      bottom: 8px;
      left: 8px;
      height: 24px;
      width: 24px;
      background-color: ${({ theme }) => theme.colors.transparentBlack};
    }

    .icon-mic-off {
      display: block;
      height: 16px;
      width: 16px;
      background-image: url(${({ micOffIconPath }) => micOffIconPath});
      background-size: auto 100%;
      background-repeat: no-repeat;
    }
  }

  // large screens
  @media (min-width: 450px) {
    grid-template-columns: ${({ participantsCount }): string => {
      return getGridTemplateColumns(participantsCount);
    }};

    grid-template-rows: ${({ currentPage }): string => {
      return getGridTemplateRows(currentPage);
    }};

    ${({ participantsCount, currentPage, pages }): string => {
      return getParticipantsStyles({
        participantsCount,
        currentPage,
        pages,
      });
    }}

    ${({ participantsCount, currentPage, pages }): string => {
      return hideParticipants({
        participantsCount,
        currentPage,
        pages,
        mobile: false,
      });
    }}
  }

  .OT_audio-level-meter {
    z-index: 100;
  }

  // Necessary as OpenTok will render the video container in a nested structure.
  & .OT_widget-container,
  & .OT_video-element {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover !important;
  }

  @media (max-width: 450px) {
    padding: 0;
    grid-gap: 4px;
    grid-template-columns: ${({ participantsCount }): string => {
      return getGridTemplateColumnsMobile(participantsCount);
    }};

    grid-template-rows: ${({ currentPage }): string => {
      return getGridTemplateRowsMobile(currentPage);
    }};

    ${({ participantsCount, currentPage, pages }): string => {
      return getParticipantsStylesMobile({
        participantsCount,
        currentPage,
        pages,
      });
    }}

    ${({ participantsCount, currentPage, pages }): string => {
      return hideParticipants({
        participantsCount,
        currentPage,
        pages,
        mobile: true,
      });
    }}
  }
`;

const LoadingMessage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  p {
    font-size: ${24 / 16}rem;
    color: ${({ theme }) => theme.colors.white};
  }
`;
