import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';

import { GlobalStyles } from './globalStyles';
import { YroomStateProvider } from './hooks';
import { AttendeeComChannelProvider } from './services/ComChannel';
import { defaultTheme } from './themes';
import './i18n/config';
import { App } from './App';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <YroomStateProvider>
        <AttendeeComChannelProvider>
          <ThemeProvider theme={defaultTheme}>
            <GlobalStyles />
            <App />
          </ThemeProvider>
        </AttendeeComChannelProvider>
      </YroomStateProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
