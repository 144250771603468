import { useTranslation } from 'react-i18next';
import { FC } from 'react';
import styled from 'styled-components';

type RatingTextProps = {
  rating: number;
};

export const RatingText: FC<RatingTextProps> = ({ rating }) => {
  const { t } = useTranslation();

  switch (rating) {
    case 5:
      return <Wrapper>{t('views.feedback.excellent')}</Wrapper>;
    case 4:
      return <Wrapper>{t('views.feedback.good')}</Wrapper>;
    case 3:
      return <Wrapper>{t('views.feedback.okay')}</Wrapper>;
    case 2:
      return <Wrapper>{t('views.feedback.bad')}</Wrapper>;
    case 1:
      return <Wrapper>{t('views.feedback.terrible')}</Wrapper>;
    default:
      return null;
  }
};

const Wrapper = styled.div`
  color: ${(props) => props.theme.colors.gray};
  line-height: 18px;
  text-align: center;
`;
