import React from 'react';
import styled, { keyframes, useTheme } from 'styled-components';
import { Icon, IconTypes } from '../Icon';

interface IconProps {
  size?: number;
}

export const LoadingIcon: React.FC<IconProps> = ({ size }) => {
  const theme = useTheme();

  return (
    <Rotate title="Loading" data-testid="loading-icon" size={size}>
      <StyledIcon
        color={theme.colors.white}
        type={IconTypes.loader}
        size={size}
      />
    </Rotate>
  );
};

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Rotate = styled.div<IconProps>`
  animation: ${rotate} infinite 2s linear;
  height: ${({ size }) => (size ? `${size}px` : '24px')};
  width: ${({ size }) => (size ? `${size}px` : '24px')};
`;

type StyledIconProps = {
  size: number | undefined;
};

const StyledIcon = styled(Icon)<StyledIconProps>`
  size: ${({ size }) => size};
`;
