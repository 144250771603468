import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { useNotifications, useSharingState } from '../../hooks';
import { Form, FormInput, useComChannel } from '../../services/ComChannel';
import { Footer } from './Footer';

export const FormView: FC = () => {
  const { showSuccessNotification } = useNotifications();
  const {
    broadcastFormValueChange,
    broadcastFormCancel,
    broadcastFormSubmit,
    onFormOpened,
    onFormClosed,
    onFormValueChanged,
  } = useComChannel();
  const frame = useRef<HTMLIFrameElement>(null);
  const [form, setForm] = useState<Form | null>(null);
  const [formInput, setFormInput] = useState<FormInput | null>(null);
  const { setSharingState } = useSharingState();

  const handleSubmitForm = () => {
    if (formInput) {
      broadcastFormSubmit(formInput);
      showSuccessNotification('views.form.formSubmitted');
    }
  };

  useEffect(() => {
    const handleShowForm = (data: Form) => {
      // Prevent the problem which is the variables go along with the
      // html does not clear itself if we set a new form html over the
      // old one
      setForm(null);

      setSharingState({ isFormSharing: true });
      setForm(data);
    };

    const handleHideForm = () => {
      // ensure that fullScreen is off when FormView goes away
      setSharingState({ isFormSharing: false, isFullScreen: false });
      setForm(null);
      setFormInput(null);
    };

    const handleChangeForm = (data: FormInput) => {
      frame.current?.contentWindow?.postMessage(data, '*');
      setFormInput(data);
    };

    const unsubscribeOnFormOpen = onFormOpened(handleShowForm);
    const unsubscribeOnFormClose = onFormClosed(handleHideForm);
    const unsubscribeOnFormValueChanged = onFormValueChanged(handleChangeForm);

    return () => {
      if (unsubscribeOnFormOpen) unsubscribeOnFormOpen();
      if (unsubscribeOnFormClose) unsubscribeOnFormClose();
      if (unsubscribeOnFormValueChanged) unsubscribeOnFormValueChanged();
    };
  }, [
    onFormClosed,
    onFormOpened,
    onFormValueChanged,
    setSharingState,
    showSuccessNotification,
  ]);

  const handleFormChange = useCallback(
    (event: MessageEvent) => {
      if (
        event?.data &&
        typeof event.data === 'string' &&
        event.data.indexOf('formViewer') > -1
      ) {
        const data = JSON.parse(event.data);
        broadcastFormValueChange(data.state);
        setFormInput(data.state);
      }
    },
    [broadcastFormValueChange]
  );

  useEffect(() => {
    const iframe = frame.current;

    if (iframe) {
      const doc = iframe.contentWindow?.document;

      if (form) {
        doc?.open();
        doc?.write(form.html);
        doc?.close();

        window.addEventListener('message', handleFormChange);
      }
    }

    return () => {
      window.removeEventListener('message', handleFormChange);
    };
  }, [handleFormChange, form]);

  return (
    form && (
      <Wrapper data-testid="form-view">
        <Header>{form.name}</Header>
        <Frame ref={frame} data-testid="form-view-form-frame" />
        <Footer
          formName={form.name}
          onCancel={broadcastFormCancel}
          onSubmit={handleSubmitForm}
          isSubmitEnabled={!!formInput?.valid}
        />
      </Wrapper>
    )
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.almostBlack};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
`;

const Header = styled.div`
  color: ${({ theme }) => theme.colors.white};
  display: none;
  padding: 16px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    display: block;
  }
`;

const Frame = styled.iframe`
  background-color: ${({ theme }) => theme.colors.white};
  border: none;
  flex-grow: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    margin: 0 16px;
  }
`;
