import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useComChannel } from '../../services/ComChannel';
import { useSharingState } from '../../hooks';

export const ScreenShareView: FC = () => {
  const { t } = useTranslation();
  const { videoSession } = useComChannel();
  const { setSharingState } = useSharingState();

  useEffect(() => {
    const handleScreenSharing = () => {
      setSharingState({ isParticipantsScreenShared: true });
    };

    const handleStopSharing = () => {
      setSharingState({
        isParticipantsScreenShared: false,
        isFullScreen: false,
      });
    };

    if (videoSession?.subscribeToScreenShare) {
      videoSession.subscribeToScreenShare(
        handleScreenSharing,
        handleStopSharing
      );
    }
  }, [setSharingState, t, videoSession]);

  return (
    <Wrapper data-testid="screen_share_wrapper">
      <ScreenShare id="participantScreenShare" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const ScreenShare = styled.div`
  height: 100%;

  .OT_widget-container {
    background-color: ${({ theme }) => theme.colors.almostBlack};
  }
`;
