import { FC } from 'react';
import styled from 'styled-components';
import { ReactComponent as StarIcon } from '../../../assets/images/star.svg';

type RatingStarProps = {
  rating: number;
  onChangeRating: (idx: number) => void;
};

export const RatingStar: FC<RatingStarProps> = ({ rating, onChangeRating }) => {
  return (
    <Wrapper>
      {Array.from(new Array(5)).map((_, idx) => {
        const currentStarValue = 5 - idx;
        const isActive = idx >= 5 - rating;

        return (
          <RatingButton
            tabIndex={5 - idx}
            key={currentStarValue}
            data-testid={`rating-star-${currentStarValue}${
              isActive ? '-active' : ''
            }`}
            isActive={isActive}
            onClick={() => {
              onChangeRating(currentStarValue);
            }}
          >
            <StarIcon />
          </RatingButton>
        );
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  width: calc(100% - 32px);
`;

const RatingButton = styled.button<{ isActive: boolean }>`
  background: none;
  border: none;
  height: 36px;
  padding: 0;

  svg {
    cursor: pointer;
    fill: ${(props) =>
      props.isActive ? props.theme.colors.warn : props.theme.colors.gray};
    opacity: ${(props) => (props.isActive ? 1 : 0.4)};
    height: 36px;
    width: 36px;
  }

  :hover {
    svg {
      fill: ${(props) => props.theme.colors.warn};
      opacity: 1;
    }

    ~ button svg {
      fill: ${(props) => props.theme.colors.warn};
      opacity: 1;
    }
  }
`;
