/* eslint-disable class-methods-use-this */
import OT, {
  SubscriberProperties,
  Publisher,
  PublisherProperties,
} from '@opentok/client';
import {
  YsErrorCallback,
  YsVideoProviderSession,
  YsVideoPublisher,
  YsVideoSubscriberCallback,
} from '../YsVideoProviderSession';
import { OpenTokVideoComponent } from './OpenTokVideoComponent';

const AUDIO_ACTIVE_TIME_MS_THRESHOLD = 300;
const AUDIO_INACTIVE_TIME_MS_THRESHOLD = 2000;

interface AudioActivity {
  timestamp: number;
  talking: boolean;
}

export class OpenTokSession implements YsVideoProviderSession {
  session: OT.Session;

  isSubscriberConnected: boolean;

  isSubscriberDisconnected: boolean;

  onSubscriberConnected: YsVideoSubscriberCallback | undefined;

  onSubscriberDisconnected: YsVideoSubscriberCallback | undefined;

  constructor(apiKey: string, sessionId: string) {
    this.session = OT.initSession(apiKey, sessionId);
    this.isSubscriberConnected = false;
    this.isSubscriberDisconnected = false;
  }

  /**
   * Initializes and returns a Publisher object.
   * You can then pass this Publisher object to Session.publish()
   * to publish a stream to a session
   */
  initPublisher(
    publisherSelector: HTMLElement | string,
    configuration = {}
  ): Publisher {
    const config: PublisherProperties = {
      insertMode: 'append',
      width: '100%',
      height: '100%',
      style: { buttonDisplayMode: 'off', nameDisplayMode: 'on' },
      ...configuration,
    };

    return OT.initPublisher(publisherSelector, config, (error) => {
      if (error) {
        // TODO: to show error popup & render black screen (when we have an agreement if needed)
      }
    });
  }

  // Connects to an OpenTok session
  connect(token: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.session.connect(token, (err) => {
        if (err) {
          reject(err);
        } else {
          resolve(true);
        }
      });
    });
  }

  // Disconnects from the OpenTok session
  disconnect(): void {
    if (this.session) {
      this.session.disconnect();
    }
  }

  // Starts publishing an audio-video stream to the session
  publish(publisher: YsVideoPublisher): void {
    if (this.session && publisher instanceof Publisher) {
      this.session.publish(publisher);
    }
  }

  unpublish(publisher: YsVideoPublisher): void {
    if (this.session && publisher instanceof Publisher) {
      this.session.unpublish(publisher);
    }
  }

  /**
   * Register a subscriber (other call participant)
   * - Subscribes to a stream
   * - Sets event listeners
   */
  registerSubscriber(
    containerSelector: HTMLElement | string,
    configuration = {},
    onError?: YsErrorCallback
  ): void {
    this.session.on('streamCreated', ({ stream }) => {
      // ignore screen share streams
      if (stream?.videoType === 'screen') {
        return;
      }

      let audioActivity: AudioActivity | null;
      const config: SubscriberProperties = {
        insertMode: 'append',
        width: '100%',
        height: '100%',
        style: { buttonDisplayMode: 'off', nameDisplayMode: 'on' },
        ...configuration,
      };

      // Subscribes to a stream that is available to the session
      const subscription = this.session.subscribe(
        stream,
        containerSelector,
        config,
        () => onError
      );

      subscription.on('videoElementCreated', (subscriptionEvent) => {
        const { element } = subscriptionEvent.target;

        if (element) {
          const audioMutedContainer = document.createElement('div');
          const audioMutedIcon = document.createElement('i');
          audioMutedContainer.classList.add('icon-mic-off-container');
          audioMutedIcon.classList.add('icon-mic-off');
          audioMutedIcon.setAttribute('e2e', 'mute_indicator');
          audioMutedContainer.append(audioMutedIcon);

          element.append(audioMutedContainer);
        }

        if (!stream?.hasAudio) {
          /**
           * if the subscriber is joining the call w/o allowing mic access,
           * set the correct class to show the muted icon
           */
          element?.classList?.add('audio-indicator-off');
        }
      });

      subscription.on('connected', () => {
        if (this.onSubscriberConnected) {
          this.onSubscriberConnected();
        }
      });

      subscription.on('destroyed', () => {
        if (this.onSubscriberDisconnected) {
          this.onSubscriberDisconnected();
        }
      });

      subscription.on('audioLevelUpdated', (subscriptionEvent) => {
        // audio on/ff indication
        if (!stream?.hasAudio) {
          subscriptionEvent?.target?.element?.classList?.add(
            'audio-indicator-off'
          );
        } else {
          subscriptionEvent?.target?.element?.classList.remove(
            'audio-indicator-off'
          );
        }

        // speech detection
        const now = Date.now();
        if (subscriptionEvent.audioLevel > 0.05) {
          if (!audioActivity) {
            audioActivity = { timestamp: now, talking: false };
          } else if (audioActivity.talking) {
            audioActivity.timestamp = now;
          } else if (
            now - audioActivity.timestamp >
            AUDIO_ACTIVE_TIME_MS_THRESHOLD
          ) {
            audioActivity.talking = true;
            subscriptionEvent?.target?.element?.classList?.add(
              'video-subscriber-audio-active'
            );
          }
        } else if (
          audioActivity &&
          now - audioActivity.timestamp > AUDIO_INACTIVE_TIME_MS_THRESHOLD
        ) {
          if (audioActivity.talking) {
            subscriptionEvent?.target?.element?.classList?.remove(
              'video-subscriber-audio-active'
            );
          }

          audioActivity = null;
        }
      });
    });
  }

  checkScreenShareCapability(): boolean {
    return true;
  }

  subscribeToScreenShare(
    sharingStarted: () => void,
    sharingEnded: () => void
  ): void {
    this.session.on('streamCreated', (event) => {
      const shouldSubscribe = event.stream.videoType === 'screen';
      if (shouldSubscribe) {
        const subscription = this.session.subscribe(
          event.stream,
          'participantScreenShare',
          {
            insertMode: 'append',
            width: '100%',
            height: '100%',
          },
          (error): void => {
            if (!error) {
              sharingStarted();
            }
          }
        );

        subscription.on('destroyed', () => {
          sharingEnded();
        });
      }
    });
  }

  initScreenShare(
    startSharing: (publisher: Publisher) => void,
    stopSharing: () => void
  ): void {
    /**
     * In order not to display the shared screen on attendee-ui side, create
     * an element that is never added to the DOM
     */
    const invisibleShareScreenElement = document.createElement('div');
    const publisher = this.initPublisher(invisibleShareScreenElement, {
      videoSource: 'screen',
    });

    publisher.on('mediaStopped', () => {
      stopSharing();
    });

    this.session.publish(publisher, (err) => {
      if (!err) {
        startSharing(publisher);
      }
    });
  }

  canCycleVideo(): Promise<boolean> {
    return new Promise((resolve) => {
      OT.getDevices((error, devices = []) => {
        const deviceHasMultipleCameras =
          devices.filter((device) => device.kind === 'videoInput').length > 1;
        resolve(deviceHasMultipleCameras);
      });
    });
  }

  setOnSubscriberConnected(callback: YsVideoSubscriberCallback): void {
    this.isSubscriberConnected = true;
    this.onSubscriberConnected = callback;
  }

  setOnSubscriberDisconnected(callback: YsVideoSubscriberCallback): void {
    this.isSubscriberDisconnected = true;
    this.onSubscriberDisconnected = callback;
  }

  renderVideoContainer(): JSX.Element {
    return <OpenTokVideoComponent />;
  }
}
