import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  /* CSS RESET */
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
 
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
 
  /* GLOBAL STYLES */
  *,
  *:before,
  *:after {
    box-sizing: border-box;
    line-height: 1.6;
    -webkit-font-smoothing: antialiased;
  }
  html {
    font-family: ${({ theme }) => theme.font.familyNormal};
    font-weight: 400;
    color: ${({ theme }) => theme.colors.text};
  
    height: 100%
  }
  
  h1, h2, h3, h4, h5, h6 {
    font-family: ${({ theme }) => theme.font.familyHeader};
    font-weight: 700;
    line-height: 130%;
    color: ${({ theme }) => theme.colors.title};
  }
  
  button {
    font: inherit;
  }
  
  body {
    height: 100%;
    background-color: ${({ theme }) => theme.backgroundColor.main}; 
  }
  
  #root {
    height: 100%;
  }
`;
