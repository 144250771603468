/* eslint-disable class-methods-use-this */
import {
  OpenVidu,
  PublisherSpeakingEvent,
  Session,
  Subscriber,
  Publisher,
  StreamEvent,
  StreamPropertyChangedEvent,
  PublisherProperties,
} from 'openvidu-browser';
import { OpenViduVideoComponent } from './OpenViduVideoComponent';
import {
  OVPublisher,
  YsVideoProviderSession,
  YsVideoPublisher,
  YsVideoSubscriberCallback,
} from '../YsVideoProviderSession';

export class OpenViduSession implements YsVideoProviderSession {
  session: Session;

  isSubscriberConnected: boolean;

  isSubscriberDisconnected: boolean;

  OV: OpenVidu;

  onSubscriberConnected: YsVideoSubscriberCallback | undefined;

  onSubscriberDisconnected: YsVideoSubscriberCallback | undefined;

  constructor() {
    this.OV = new OpenVidu();
    this.OV.enableProdMode();

    this.session = this.OV.initSession();

    this.isSubscriberConnected = false;
    this.isSubscriberDisconnected = false;
  }

  /**
   * Initializes and returns a Publisher object.
   * You can then pass this Publisher object to Session.publish()
   * to publish a stream to a session
   */
  initPublisher(
    containerSelector: HTMLElement | string,
    configuration = {
      videoSource: true,
    }
  ): Publisher {
    const config: PublisherProperties = {
      insertMode: 'append',
      ...configuration,
    };

    const publisher: OVPublisher = this.OV.initPublisher('', config);

    this.injectVideoWidget(
      publisher,
      containerSelector,
      'OT_mirrored OT_root OT_publisher OT_fit-mode-contain OT_mini'
    );

    /**
     * openVidu does not support explicit destruction of publisher;
     * we add this method artificially
     */
    publisher.destroy = (): void => {};

    return publisher;
  }

  // Connects to an OpenVidu session
  connect(token: string): Promise<boolean> {
    return this.session.connect(token);
  }

  // Disconnects from an OpenVidu session
  disconnect(): void {
    if (this.session) {
      this.session.disconnect();
    }
  }

  // Starts publishing an audio-video stream to the session
  publish(publisher: YsVideoPublisher): void {
    if (this.session && publisher instanceof Publisher) {
      this.session.publish(publisher);
    }
  }

  unpublish(publisher: YsVideoPublisher): void {
    if (this.session && publisher instanceof Publisher) {
      this.session.unpublish(publisher);
    }
  }

  /**
   * Register a subscriber (other call participant)
   * - Subscribes to a stream
   * - Sets event listeners
   */
  registerSubscriber(containerSelector: HTMLElement | string): void {
    this.session.on('streamCreated', (event) => {
      if (event instanceof StreamEvent) {
        const { stream } = event;

        const subscription = this.session.subscribe(stream, '');

        if (this.onSubscriberConnected) {
          this.onSubscriberConnected();
        }

        this.injectVideoWidget(
          subscription,
          containerSelector,
          'OT_root OT_subscriber OT_fit-mode-contain'
        );
      }
    });

    this.session.on('streamDestroyed', () => {
      if (this.onSubscriberDisconnected) {
        this.onSubscriberDisconnected();
      }
    });

    this.session.on('publisherStartSpeaking', (event) => {
      if (event instanceof PublisherSpeakingEvent) {
        const videoWidget = document.querySelector(
          `[stream-id="${event.streamId}"]`
        );

        if (videoWidget) {
          videoWidget.classList.add('video-subscriber-audio-active');
        }
      }
    });

    this.session.on('publisherStopSpeaking', (event) => {
      if (event instanceof PublisherSpeakingEvent) {
        const videoWidget = document.querySelector(
          `[stream-id="${event.streamId}"]`
        );

        if (videoWidget) {
          videoWidget.classList.remove('video-subscriber-audio-active');
        }
      }
    });
  }

  injectVideoWidget(
    subject: Subscriber | OVPublisher,
    containerSelector: HTMLElement | string,
    cssClass: string
  ): void {
    const wrapper = document.createElement('div');
    wrapper.setAttribute('class', cssClass);
    wrapper.setAttribute('stream-id', subject.stream.streamId);
    wrapper.style.overflow = 'hidden';

    const widgetContainer = document.createElement('div');
    widgetContainer.setAttribute('class', 'OT_widget-container');
    widgetContainer.style.width = '100%';
    widgetContainer.style.height = '100%';

    const videoPoster = document.createElement('div');
    videoPoster.setAttribute('class', 'OT_video-poster');
    videoPoster.style.display = 'none';

    const audioMutedContainer = document.createElement('div');
    const audioMutedIcon = document.createElement('i');
    audioMutedContainer.classList.add('icon-mic-off-container');

    audioMutedIcon.classList.add('icon-mic-off');
    audioMutedIcon.setAttribute('e2e', 'mute_indicator');
    audioMutedContainer.append(audioMutedIcon);

    // if a subscriber joined w/o allowing mic, or the mic is off, add muted icon
    if (!subject?.stream?.hasAudio || !subject?.stream?.audioActive) {
      wrapper.classList.add('audio-indicator-off');
    }

    // if a subscriber joined w/o allowing video, or the video is off, add correct style
    if (!subject?.stream?.hasVideo || !subject?.stream?.videoActive) {
      videoPoster.style.display = 'block';
      wrapper.classList.add('OT_audio-only');
    }

    wrapper.append(widgetContainer);
    wrapper.append(videoPoster);
    wrapper.append(audioMutedContainer);

    let container: HTMLElement | null;
    if (typeof containerSelector === 'string') {
      container = document.getElementById(containerSelector);
    } else {
      container = containerSelector;
    }

    if (container) {
      container.append(wrapper);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    subject.on('streamPropertyChanged', (event): void => {
      if (event instanceof StreamPropertyChangedEvent) {
        if (!event?.stream?.videoActive) {
          videoPoster.style.display = 'block';
          audioMutedContainer.style.display = 'block';
          wrapper.classList.add('OT_audio-only');
        } else {
          videoPoster.style.display = 'none';
          audioMutedContainer.style.display = 'none';
          wrapper.classList.remove('OT_audio-only');
        }

        if (!event?.stream?.audioActive) {
          wrapper.classList.add('audio-indicator-off');
        } else {
          wrapper.classList.remove('audio-indicator-off');
        }
      }
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    subject.on('videoElementDestroyed', () => {
      wrapper.remove();
    });

    const video = subject.createVideoElement(wrapper);
    video.classList.add('OT_video-element');
  }

  initScreenShare(): void {}

  subscribeToScreenShare(): void {}

  checkScreenShareCapability(): boolean {
    return false;
  }

  canCycleVideo(): Promise<boolean> {
    return new Promise((resolve) => {
      resolve(false);
    });
  }

  setOnSubscriberConnected(callback: YsVideoSubscriberCallback): void {
    this.isSubscriberConnected = true;
    this.onSubscriberConnected = callback;
  }

  setOnSubscriberDisconnected(callback: YsVideoSubscriberCallback): void {
    this.isSubscriberDisconnected = true;
    this.onSubscriberDisconnected = callback;
  }

  renderVideoContainer(): JSX.Element {
    return <OpenViduVideoComponent />;
  }
}
