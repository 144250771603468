import { FC, useLayoutEffect } from 'react';
import styled from 'styled-components';

const browser = {
  // Edge (legacy)
  isEdgeLegacy: navigator.userAgent.match(/Edge\/\d+/i),

  // Edge (based on chromium)
  isEdgeChromium: navigator.userAgent.match(/Edg\/\d+/i),
};

type PointerAnimationProps = {
  x: number;
  y: number;
  isOrganizer: boolean;
  callback: () => void;
};

export const PointerAnimation: FC<PointerAnimationProps> = ({
  callback,
  ...rest
}) => {
  useLayoutEffect(() => {
    setTimeout(() => callback(), 1000);
  }, [callback]);

  return (
    <Wrapper {...rest}>
      {!browser.isEdgeLegacy && (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <circle cx="50" cy="50" r="0" strokeWidth="5" fill="none">
            <animate
              attributeName="r"
              begin="0s"
              dur="1s"
              repeatCount="once"
              from="0"
              to="50"
            />
            <animate
              attributeName="opacity"
              begin="0.5s"
              dur="0.5s"
              repeatCount="once"
              from="1"
              to="0"
            />
          </circle>
          <circle cx="50" cy="50" r="0" strokeWidth="3" fill="none">
            <animate
              attributeName="r"
              begin="0.2s"
              dur="0.8s"
              repeatCount="once"
              from="0"
              to="40"
            />
            <animate
              attributeName="opacity"
              begin="0.5s"
              dur="0.5s"
              repeatCount="once"
              from="1"
              to="0"
            />
          </circle>
          <circle cx="50" cy="50" r="0" strokeWidth="1" fill="none">
            <animate
              attributeName="r"
              begin="0.4s"
              dur="0.6s"
              repeatCount="once"
              from="0"
              to="30"
            />
            <animate
              attributeName="opacity"
              begin="0.5s"
              dur="0.5s"
              repeatCount="once"
              from="1"
              to="0"
            />
          </circle>
        </svg>
      )}
      {browser.isEdgeLegacy && (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
          <circle cx="50" cy="50" r="45" strokeWidth="5" fill="none" />
          <circle cx="50" cy="50" r="35" strokeWidth="3" fill="none" />
          <circle cx="50" cy="50" r="25" strokeWidth="1" fill="none" />
        </svg>
      )}
    </Wrapper>
  );
};

type WrapperProps = Omit<PointerAnimationProps, 'callback'>;

const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  top: ${(props) => props.y}px;
  left: ${(props) => props.x}px;
  width: 100px;
  height: 100px;
  z-index: 1;

  svg circle {
    stroke: ${(props) =>
      props.isOrganizer
        ? props.theme.colors.error
        : props.theme.colors.primary.main};
  }
`;
