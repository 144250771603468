export const getStreamName = (): string => {
  const { search } = window.location;
  const searchParams = new URLSearchParams(search);
  const [name, email] = [
    searchParams.get('name') || '',
    searchParams.get('email') || '',
  ];

  if (!name && !email) {
    return '';
  }

  if (!name && email) {
    return `(${email})`;
  }

  if (name && !email) {
    return name;
  }

  return `${name} (${email})`;
};

// `PropertyKey` is short for "string | number | symbol"
export const hasKey = <T extends Record<PropertyKey, unknown> | Array<number>>(
  obj: T,
  key: PropertyKey
): key is keyof T => {
  return key in obj;
};
