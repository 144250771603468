import React from 'react';
import styled from 'styled-components';

export const OpenTokVideoComponent: React.FC = () => {
  return <Wrapper data-testid="ysura-video-publisher" id="publisher" />;
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;
