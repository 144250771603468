import { FC, useMemo } from 'react';
import styled, { DefaultTheme } from 'styled-components';
import { getParticipantBackgroundColor } from './videoStyleHelper';

type ParticipantPlaceholderProps = {
  participantsCount: number;
  maxParticipantNumberAllowed: number;
};

export const ParticipantPlaceholder: FC<ParticipantPlaceholderProps> = ({
  participantsCount,
  maxParticipantNumberAllowed,
}) => {
  const displayParticipantNumber = useMemo(() => {
    if (participantsCount - maxParticipantNumberAllowed === 1) {
      return participantsCount;
    }
    const returnNumber =
      participantsCount > maxParticipantNumberAllowed
        ? maxParticipantNumberAllowed
        : participantsCount;
    return returnNumber > 0 ? returnNumber : 0;
  }, [maxParticipantNumberAllowed, participantsCount]);

  return (
    <>
      {Array.from(new Array(displayParticipantNumber)).map((_, idx) => {
        const displayNumber = idx + 1;
        return (
          <Participant
            key={displayNumber}
            participantNumber={displayNumber}
            data-testid="participant-placeholder"
          >
            {`P${displayNumber}`}
          </Participant>
        );
      })}

      {participantsCount > maxParticipantNumberAllowed + 1 && (
        <HiddenParticipantNumber>
          {`+${participantsCount - maxParticipantNumberAllowed}`}
        </HiddenParticipantNumber>
      )}
    </>
  );
};

type ParticipantProps = {
  participantNumber: number;
  theme: DefaultTheme;
};

const Participant = styled.div<ParticipantProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  min-width: 64px;
  border-radius: 16px;
  font-size: ${24 / 16}rem;
  background: ${(props) => getParticipantBackgroundColor(props)};
  color: ${({ theme }) => theme.colors.white};
`;

const HiddenParticipantNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  min-width: 64px;
  border-radius: 16px;
  font-size: ${24 / 16}rem;
  background-color: ${({ theme }) => theme.colors.gray};
  color: ${({ theme }) => theme.colors.white};
`;
