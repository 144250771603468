import { Routes, Route } from 'react-router-dom';

import { PrecallTest } from './views/PrecallTest';
import { MainLayout } from './views/Layout';
import { RoomView } from './views/Room';
import { Feedback } from './views/Feedback';

export const App: React.FC = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<PrecallTest />} />
        <Route path="/feedback/:roomId" element={<Feedback />} />
      </Route>
      <Route path="/room/:roomId" element={<RoomView />} />
    </Routes>
  );
};
