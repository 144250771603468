import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { getRealm } from '../helpers';

const YSURA_REALM_HEADER = 'X-Ysura-Realm';

export type Room = {
  id: string;
};

export type RestApi = {
  getRoom: (code: string) => Promise<Room>;
  addFeedback: (roomId: string, rating: number, text: string) => Promise<void>;
};

export const useRestApi = (): RestApi => {
  const BASE_URL = process.env.REACT_APP_API_URL || '';
  const realm = getRealm();

  const requestOptions = {
    baseURL: BASE_URL,
    headers: {
      [YSURA_REALM_HEADER]: realm,
    },
  };

  const getRoom = (code: string): Promise<Room> => {
    return Promise.resolve(
      axios.get<Room>(`/room/${code}`, requestOptions).then(({ data }) => data)
    );
  };

  const addFeedback = (roomId: string, rating: number, text: string) => {
    return Promise.resolve(
      axios
        .post(
          `/feedback`,
          {
            id: uuid(),
            roomId,
            rating,
            text,
          },
          requestOptions
        )
        .then(() => {})
    );
  };

  return {
    getRoom,
    addFeedback,
  };
};
