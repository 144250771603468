import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { IconTypes } from '../Icon';
import { IconButton, IconButtonTypes } from '../IconButton';
import { YsuraVideoEvents } from '../../enums/YsuraVideo';
import { useSharingState } from '../../hooks';
import { useComChannel } from '../../services/ComChannel';
import { featureEnabled, features } from '../../services/FeatureFlag';
import { useLocalStorageState } from '../../hooks/LocalStorageState';

export const VideoControls: FC = () => {
  const [isVideoEnabled] = useLocalStorageState('isYroomVideoEnabled');
  const [isMicEnabled] = useLocalStorageState('isYroomMicEnabled');
  const [isCameraOn, setIsCameraOn] = useState(true);
  const [isMicOn, setIsMicOn] = useState(true);
  const [isScreenShareAvailable, setIsScreenShareAvailable] = useState(false);
  const [isCycleCameraAvailable, setIsCycleCameraEnabled] = useState(false);
  const { videoSession, roomInfo } = useComChannel();

  /**
   * if video or mic are disabled by the user during the precall test,
   * make sure they are off (affects the button icon and label only)
   */
  useEffect(() => {
    if (!isVideoEnabled) {
      setIsCameraOn(false);
    }

    if (!isMicEnabled) {
      setIsMicOn(false);
    }
  }, [isVideoEnabled, isMicEnabled]);

  useEffect(() => {
    if (videoSession) {
      const { roomMetaData } = roomInfo;
      const isMetaDataScreenShareAvailable = !!roomMetaData?.screenShareEnabled;
      const isScreenShareCapabilityAvailable =
        videoSession.checkScreenShareCapability();
      const isScreenShareFeatureEnabled = featureEnabled(
        features.ATTENDEE_SCREEN_SHARING
      );
      videoSession.canCycleVideo().then((isCycleCameraEnabled) => {
        setIsCycleCameraEnabled(isCycleCameraEnabled);
      });

      setIsScreenShareAvailable(
        isScreenShareCapabilityAvailable &&
          isScreenShareFeatureEnabled &&
          isMetaDataScreenShareAvailable
      );
    }
  }, [roomInfo, videoSession]);

  const { t } = useTranslation();
  const {
    isFullScreen,
    isFormSharing,
    isMediaSharing,
    isOwnScreenShared,
    isParticipantsScreenShared,
  } = useSharingState();
  const isSharing =
    isFormSharing || isMediaSharing || isParticipantsScreenShared;

  const handleToggleVideo = () => {
    setIsCameraOn((cameraOn) => {
      const toggleCameraEvent = new CustomEvent(YsuraVideoEvents.toggleCamera, {
        detail: {
          isCameraOn: cameraOn,
        },
      });

      window.dispatchEvent(toggleCameraEvent);
      return !cameraOn;
    });
  };

  const handleToggleAudio = () => {
    setIsMicOn((micOn) => {
      const toggleMicEvent = new CustomEvent(YsuraVideoEvents.toggleMic, {
        detail: {
          isMicOn: micOn,
        },
      });

      window.dispatchEvent(toggleMicEvent);
      return !micOn;
    });
  };

  const handleCycleCamera = () => {
    const cycleCameraEvent = new CustomEvent(YsuraVideoEvents.cycleCamera);
    window.dispatchEvent(cycleCameraEvent);
  };

  const handleShareScreen = () => {
    const shareScreenEvent = new CustomEvent(YsuraVideoEvents.shareScreen);
    window.dispatchEvent(shareScreenEvent);
  };

  const cameraLabel = useMemo(() => {
    if (isSharing) {
      return '';
    }

    return isCameraOn ? t('views.room.cameraOn') : t('views.room.cameraOff');
  }, [isCameraOn, isSharing, t]);

  const audioLabel = useMemo(() => {
    if (isSharing) {
      return '';
    }

    return isMicOn ? t('views.room.micOn') : t('views.room.muted');
  }, [isMicOn, isSharing, t]);

  return (
    <Wrapper isSharing={isSharing}>
      <ButtonContainer isFullScreen={isFullScreen} isSharing={isSharing}>
        <IconButton
          variant={IconButtonTypes.cameraToggle}
          icon={isCameraOn ? IconTypes.camera : IconTypes.cameraOff}
          label={cameraLabel}
          active={isCameraOn}
          onClick={handleToggleVideo}
          disabled={!isVideoEnabled || !videoSession}
        />

        <IconButton
          variant={IconButtonTypes.micToggle}
          icon={isMicOn ? IconTypes.mic : IconTypes.micOff}
          label={audioLabel}
          active={isMicOn}
          onClick={handleToggleAudio}
          disabled={!isMicEnabled || !videoSession}
        />

        {isCycleCameraAvailable && (
          <CycleCameraContainer isSharing={isSharing}>
            <IconButton
              variant={IconButtonTypes.cycleCamera}
              icon={IconTypes.cycleCamera}
              label={isSharing ? '' : t('views.room.cycleCamera')}
              onClick={handleCycleCamera}
              disabled={!videoSession}
            />
          </CycleCameraContainer>
        )}

        {isScreenShareAvailable && (
          <IconButton
            variant={IconButtonTypes.screenShare}
            icon={IconTypes.screenShare}
            label={isSharing ? '' : t('views.room.screenShare')}
            active={isOwnScreenShared}
            onClick={handleShareScreen}
            disabled={isSharing || !videoSession}
            testId="button-screenShare"
          />
        )}
      </ButtonContainer>
    </Wrapper>
  );
};

type WrapperAndContainerProps = {
  isSharing?: boolean;
  isFullScreen?: boolean;
};

const Wrapper = styled.div<WrapperAndContainerProps>`
  display: flex;
  align-items: center;
  justify-content: ${({ isSharing }) =>
    isSharing ? 'space-around' : 'center'};
  column-gap: ${({ isSharing }) => (isSharing ? '16px' : 0)};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    // when sharing, move the buttons to the start of the container
    justify-content: ${({ isSharing }) =>
      isSharing ? 'flex-start' : 'space-around'};
    column-gap: 16px;
  }
`;

// When sharing & isFullScreen or mobile landscape view are true, show buttons in 1 column
const ButtonContainer = styled.div<WrapperAndContainerProps>`
  display: ${({ isFullScreen }) => (isFullScreen ? 'flex' : 'grid')};

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    display: ${({ isSharing }) => (isSharing ? 'flex' : 'grid')};
  }

  grid-auto-flow: column;
  grid-auto-columns: minmax(0, 1fr);
  grid-gap: 16px;

  // will only apply when display is flex
  flex-direction: column;
  row-gap: 16px;
`;

// When sharing & mobile is in landscape view are true, do not show the button
const CycleCameraContainer = styled.div<WrapperAndContainerProps>`
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    display: ${({ isSharing }) => (isSharing ? 'none' : 'block')};
  }
`;
