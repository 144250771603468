import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { YsuraVideoEvents } from '../../enums';
import { useComChannel } from '../../services/ComChannel';
import { ParticipantPlaceholder } from './ParticipantPlaceholder';
import { ParticipantsVideo } from './ParticipantsVideo';
import { OwnVideo } from './OwnVideo';
import { YsVideoPublisher } from '../../services/rtcClient';
import { initializeVideo } from './videoHelper';
import UserIcon from '../../assets/images/userIcon.svg';
import {
  UserPreferences,
  useUserPreferences,
  useNotifications,
  useSharingState,
} from '../../hooks';
import {
  getOwnVideoHeight,
  getOwnVideoWidth,
  getVideoWrapperHeight,
} from './videoStyleHelper';
import { useLocalStorageState } from '../../hooks/LocalStorageState';

const SLOT_HEIGHT = 64;

type VideoViewProps = {
  isSharing?: boolean;
};

export const VideoView: FC<VideoViewProps> = ({ isSharing = false }) => {
  const subscriberRef = useRef<HTMLDivElement>(null);
  const publisherRef = useRef<HTMLDivElement>(null);

  const [isVideoEnabled] = useLocalStorageState('isYroomVideoEnabled');
  const [isMicEnabled] = useLocalStorageState('isYroomMicEnabled');
  const [participantsCount, setParticipantsCount] = useState(0);
  const [publisher, setPublisher] = useState<YsVideoPublisher | null>(null);
  const [screenSharePublisher, setScreenSharePublisher] =
    useState<YsVideoPublisher | null>(null);

  const navigate = useNavigate();
  const { roomId } = useParams();
  const { t } = useTranslation();
  const { roomInfo, videoSession, leaveCall } = useComChannel();

  const { getUserPreferences } = useUserPreferences();
  const { video: videoPreferences }: UserPreferences = getUserPreferences();
  const { showNotification, showInfoNotification } = useNotifications();

  const { isFullScreen, isOwnScreenShared, setSharingState } =
    useSharingState();

  const [maxParticipantNumberAllowed, setMaxParticipantNumberAllowed] =
    useState(0);
  const wrapperRef = useCallback(
    (node: HTMLDivElement) => {
      if (node && isFullScreen) {
        setMaxParticipantNumberAllowed(
          // The minus is the space for an own video and an +Number of hidden participants.
          // The 16 at the end is the gap between participant placeholder.
          // Detail description should be: Max Participant Number Allowed = (The Available Space Height - The Height belongs to Own Video - The Height belongs to Hidden Participant Number) / (The Height per SLOT + The Gap between 2 SLOTs)
          Math.round((node.clientHeight - SLOT_HEIGHT * 2) / (SLOT_HEIGHT + 16))
        );
      }
    },
    [isFullScreen]
  );

  /* ***************************************
   * Handle notifications (e.g. disconnect)
   ***************************************** */

  useEffect(() => {
    const handleDisconnect = (e: CustomEventInit): void => {
      // If room limit has been exceeded, show notification and navigate to homescreen
      if (e.detail && e.detail.notification) {
        const { message } = e.detail.notification;

        showInfoNotification(message);
        setTimeout(() => {
          leaveCall();
          navigate('/');
        }, 3000);

        return;
      }

      // If the organizer closes the room, navigate the attendees to the feedback page
      leaveCall();
      navigate(`/feedback/${roomId}`);
    };

    const handleNotifications = (e: CustomEventInit) => {
      const { notification } = e.detail;
      const { message, type = 'success' } = notification;

      showNotification(message, type);
    };

    window.addEventListener(YsuraVideoEvents.disconnected, handleDisconnect);
    window.addEventListener(YsuraVideoEvents.connected, handleNotifications);
    window.addEventListener(
      YsuraVideoEvents.organizerNotification,
      handleNotifications
    );

    return (): void => {
      window.removeEventListener(
        YsuraVideoEvents.connected,
        handleNotifications
      );
      window.removeEventListener(
        YsuraVideoEvents.disconnected,
        handleDisconnect
      );
      window.removeEventListener(
        YsuraVideoEvents.organizerNotification,
        handleNotifications
      );
    };
  }, [navigate, roomId, showInfoNotification, showNotification, t, leaveCall]);

  /* ***************************************
   * Connect subscriber and provider videos
   ***************************************** */

  useEffect(() => {
    const onSubscriberConnected = (): void => {
      setParticipantsCount((oldCount) => oldCount + 1);
    };

    const onSubscriberDisconnected = (): void => {
      setParticipantsCount((oldCount) => oldCount - 1);
    };

    if (videoSession && !publisher) {
      // start video and use the destroy method for cleanup when the component unmounts
      const { token = '' } = roomInfo;

      const { publisher: initializedVideoPublisher } = initializeVideo({
        token,
        session: videoSession,
        publisherRef,
        subscriberRef,
        videoPreferences: videoPreferences || { fitMode: 'cover' },
        onSubscriberConnected,
        onSubscriberDisconnected,
        isVideoEnabled: !!isVideoEnabled,
        isMicEnabled: !!isMicEnabled,
      });

      setPublisher(initializedVideoPublisher);
    }
  }, [
    videoSession,
    videoPreferences,
    publisher,
    roomInfo,
    isVideoEnabled,
    isMicEnabled,
  ]);

  /* *****************************
   * Video control handlers
   ******************************* */

  const handleToggleCamera = useCallback(
    (e: CustomEventInit) => {
      const { isCameraOn } = e.detail;
      publisher?.publishVideo(!isCameraOn);
    },
    [publisher]
  );

  const handleToggleMic = useCallback(
    (e: CustomEventInit) => {
      const { isMicOn } = e.detail;
      publisher?.publishAudio(!isMicOn);
    },
    [publisher]
  );

  const handleCycleCamera = useCallback(() => {
    if (publisher?.cycleVideo) {
      publisher.cycleVideo();
    }
  }, [publisher]);

  const handleStopScreenSharing = useCallback((): void => {
    setSharingState({ isOwnScreenShared: false });
    if (screenSharePublisher) {
      videoSession?.unpublish(screenSharePublisher);
    }
  }, [screenSharePublisher, videoSession, setSharingState]);

  const handleShareScreen = useCallback(() => {
    if (isOwnScreenShared) {
      handleStopScreenSharing();
      return;
    }

    if (videoSession?.initScreenShare) {
      videoSession.initScreenShare(
        (incomingPublisher: YsVideoPublisher) => {
          setScreenSharePublisher(incomingPublisher);
          setSharingState({ isOwnScreenShared: Boolean(incomingPublisher) });
        },
        () => {
          handleStopScreenSharing();
        }
      );
    }
  }, [
    handleStopScreenSharing,
    isOwnScreenShared,
    setSharingState,
    videoSession,
  ]);

  useEffect(() => {
    window.addEventListener(YsuraVideoEvents.toggleMic, handleToggleMic);
    window.addEventListener(YsuraVideoEvents.toggleCamera, handleToggleCamera);
    window.addEventListener(YsuraVideoEvents.cycleCamera, handleCycleCamera);
    window.addEventListener(YsuraVideoEvents.shareScreen, handleShareScreen);

    return (): void => {
      window.removeEventListener(YsuraVideoEvents.toggleMic, handleToggleMic);
      window.removeEventListener(
        YsuraVideoEvents.toggleCamera,
        handleToggleCamera
      );
      window.removeEventListener(
        YsuraVideoEvents.cycleCamera,
        handleCycleCamera
      );
      window.removeEventListener(
        YsuraVideoEvents.shareScreen,
        handleShareScreen
      );
    };
  }, [
    handleToggleCamera,
    handleToggleMic,
    handleCycleCamera,
    handleShareScreen,
  ]);

  /* *****************************
   * Cleanup when the Video component unmounts
   ******************************* */

  useEffect(() => {
    return (): void => {
      if (publisher && publisher.destroy) {
        publisher.destroy();
      }

      if (publisher) {
        setParticipantsCount(0);
        setPublisher(null);
      }
    };
  }, [publisher]);

  return (
    <Wrapper ref={wrapperRef} isSharing={isSharing} isFullScreen={isFullScreen}>
      <VideoWrapper
        isSharing={isSharing}
        isFullScreen={isFullScreen}
        iconPath={UserIcon}
        data-testid="room_videos_wrapper"
      >
        {/* When fullScreen, render a list of participants with colored boxes */}
        {isFullScreen && (
          <ParticipantPlaceholder
            participantsCount={participantsCount}
            maxParticipantNumberAllowed={maxParticipantNumberAllowed}
          />
        )}

        <ParticipantsVideoWrapper
          isSharing={isSharing}
          isFullScreen={isFullScreen}
        >
          <ParticipantsVideo
            ref={subscriberRef}
            participants={participantsCount}
          />
        </ParticipantsVideoWrapper>
        <OwnVideoWrapper isSharing={isSharing} isFullScreen={isFullScreen}>
          <OwnVideo ref={publisherRef} isSharing={isSharing} />
        </OwnVideoWrapper>
      </VideoWrapper>
    </Wrapper>
  );
};

type VideoProps = {
  isSharing?: boolean;
  isFullScreen?: boolean;
  iconPath?: string;
};

// TODO: set correct media queries (to talk to the designers)
const Wrapper = styled.div<VideoProps>`
  height: ${(props) => getVideoWrapperHeight(props)};
  padding: ${({ isFullScreen }) => (isFullScreen ? 0 : '16px 0')};
  flex-grow: 1;

  @media (min-width: 1440px) {
    width: ${({ isSharing }) => (isSharing ? '100%' : '75%')};
    margin: 0 auto;
  }

  @media (min-width: 1600px) {
    width: ${({ isSharing }) => (isSharing ? '100%' : '70%')};
    margin: 0 auto;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    height: calc(100% - 40px - 52px);
  }

  // Hide all videos if sharing and mobile landscape orientation
  ${({ isSharing, theme }) =>
    isSharing &&
    `
      @media (max-width: ${theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
        display: none;
      }
    `}
`;

const VideoWrapper = styled.div<VideoProps>`
  display: flex;
  align-items: center;
  height: 100%;
  column-gap: 16px;

  ${({ isSharing, theme }) =>
    isSharing &&
    `
      flex-direction: column;
      justify-content: center;
      row-gap: 4px;

      @media (max-width: ${theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
        justify-content: flex-start;
      }
    `}

  ${({ isFullScreen }) =>
    isFullScreen &&
    `
      justify-content: flex-start;
      row-gap: 8px;
    `}

  && {
    // For keeping the 4/3 aspect ratio
    .OT_subscriber,
    .OT_publisher {
      height: auto !important;
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      min-width: unset;
      min-height: unset;
      aspect-ratio: 4/3;

      ${({ isFullScreen }) =>
        isFullScreen &&
        `
          aspect-ratio: 1/1;
          height: 64px !important;
          width: 64px !important;
        `}

      ${({ isSharing, theme }) =>
        isSharing &&
        `
          @media (max-width: ${theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
            aspect-ratio: 1/1;
            height: 64px !important;
            width: 64px !important;
          }
        }
      `}
    }

    .OT_video-poster {
      display: block;
      background-image: ${({ iconPath }) => `url(${iconPath})`};
      background-size: auto 50%;
      opacity: 1;
    }

    .OT_audio-level-meter {
      display: none !important;
    }

    .OT_bar {
      background: none !important;
    }

    .OT_name {
      display: ${({ isSharing }) =>
        isSharing ? 'none !important' : 'inline-block !important'};
      line-height: 24px;
      left: 8px;
      margin-top: 8px;
      border-radius: 8px;
      padding: 0 8px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 50%;
      width: fit-content;
      background: ${({ theme }) => theme.colors.transparentBlack};
      font-size: 12px;
      height: 23px;
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
      flex-direction: column;
      justify-content: center;
      row-gap: 4px;

      // hide video name bar when small screen size and sharing is active
      .OT_name {
        display: ${({ isSharing }) => (isSharing ? 'none !important' : '')};
      }
    }
  }
`;

const ParticipantsVideoWrapper = styled.div<VideoProps>`
  display: ${({ isFullScreen }) => (isFullScreen ? 'none' : 'block')};
  width: ${({ isSharing }) => (isSharing ? '100%' : '75%')};
  height: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    width: 100%;
    height: 80%;

    // when sharing is active, hide paricipant video for small screens
    ${({ isSharing }) =>
      isSharing &&
      `
        display: none;
      `}
  }
`;

const OwnVideoWrapper = styled.div<VideoProps>`
  height: ${(props) => getOwnVideoHeight(props)};
  width: ${(props) => getOwnVideoWidth(props)};

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    width: ${({ isSharing }) => (isSharing ? '100%' : 'calc(50% - 4px)')};
    height: ${({ isSharing }) => (isSharing ? '64px' : '20%')};
  }

  ${({ isSharing, theme }) =>
    isSharing &&
    `
      @media (max-width: ${theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
        width: 64px;  
        height: 64px;
      }
  `}
`;
