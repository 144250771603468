const TEST_DOMAINS = ['localhost', '.dev.yroom.app'];

export const features = {
  SCHEDULING: 'SCHEDULING',
  MULTI_ATTENDEES: 'MULTI_ATTENDEES',
  NEW_ATTENDEE_MGMT: 'NEW_ATTENDEE_MGMT',
  SAMPLES: 'SAMPLES',
  ATTENDEE_SCREEN_SHARING: 'ATTENDEE_SCREEN_SHARING',
  ATTENDEE_FEEDBACK: 'ATTENDEE_FEEDBACK',
};

const FEATURE_MAP = {
  [features.SCHEDULING]: [
    'dummy.yroom.app',
    'isarlife.yroom.app',
    'isarlife-en.yroom.app',
    'ysura.yroom.app',
    'release-dsde.yroom.app',
    'release-ashfield-de.yroom.app',
    'bms-ashfield.yroom.app',
    'sanofi-pd.yroom.app',
    'ainet.yroom.app',
    'vertex-ashfield.yroom.app',
  ],
  [features.MULTI_ATTENDEES]: [
    'dummy.yroom.app',
    'isarlife.yroom.app',
    'isarlife-en.yroom.app',
    'ysura.yroom.app',
    'nnp.yroom.eu',
    'ainet.yroom.app',
    'sanofi-pd.yroom.app',
  ],
  // Currently not mature, do not use.
  [features.NEW_ATTENDEE_MGMT]: [],
  [features.SAMPLES]: [
    'dummy.yroom.app',
    'isarlife.yroom.app',
    'isarlife-en.yroom.app',
    'dsde.yroom.app',
    'release-dsde.yroom.app',
    'training-dsde.yroom.app',
    'release-ashfield-de.yroom.app',
    'sanofi-pd.yroom.app',
  ],
  [features.ATTENDEE_SCREEN_SHARING]: [
    'dummy.yroom.app',
    'isarlife.yroom.app',
    'isarlife-en.yroom.app',
    'ysura.yroom.app',
    'ainet.yroom.app',
    'vertex-ashfield.yroom.app',
  ],
  [features.ATTENDEE_FEEDBACK]: [
    'dummy.yroom.app',
    'isarlife.yroom.app',
    'isarlife-en.yroom.app',
    'ysura.yroom.app',
    'bms-ashfield.yroom.app',
    'ainet.yroom.app',
    'vertex-ashfield.yroom.app',
  ],
};

export const featureEnabled = (feature: string): boolean => {
  if (!feature) {
    return false;
  }

  const { href } = window.location;
  const foundDomain = TEST_DOMAINS.find((i) => href.includes(i));

  if (foundDomain) {
    return true;
  }

  if (!FEATURE_MAP[feature]) {
    return false;
  }

  const foundFeature = FEATURE_MAP[feature].find((i) => href.includes(i));
  return !!foundFeature;
};
