import { FC, useCallback, useEffect, useReducer } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Button } from '../../components/Button';
import { JoinRoomForm } from './JoinRoomForm';
import { StateType } from './type';
import { socket } from './socket';
import { AvailabilityTest } from './AvailabilityTest';
import { useLocalStorageState } from '../../hooks/LocalStorageState';

const initialState: StateType = {
  isTested: false,
  isTesting: false,
  videoAvailability: 'idle',
  micAvailability: 'idle',
  connectionAvailability: 'idle',
  connectAttempt: 0,
};

export const PrecallTest: FC = () => {
  const { t } = useTranslation();
  const [, setIsMicEnabled] = useLocalStorageState('isYroomMicEnabled', false);
  const [, setIsVideoEnabled] = useLocalStorageState(
    'isYroomVideoEnabled',
    false
  );

  const [state, setState] = useReducer(
    (currentState: StateType, newState: Partial<StateType>) => ({
      ...currentState,
      ...newState,
    }),
    initialState
  );

  const onTest = useCallback(() => {
    setState({
      isTesting: true,
      videoAvailability: 'idle',
      micAvailability: 'idle',
      connectionAvailability: 'idle',
    });

    if (!navigator?.mediaDevices?.getUserMedia) {
      setState({
        videoAvailability: 'error',
        micAvailability: 'error',
      });
    } else {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          setIsVideoEnabled(true);
          setState({
            videoAvailability: 'success',
          });
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        })
        .catch(() => {
          setIsVideoEnabled(false);
          setState({
            videoAvailability: 'error',
          });
        });

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          setIsMicEnabled(true);
          setState({
            micAvailability: 'success',
          });
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        })
        .catch(() => {
          setIsMicEnabled(false);
          setState({
            micAvailability: 'error',
          });
        });

      socket.open();
    }
  }, [setIsMicEnabled, setIsVideoEnabled]);

  useEffect(() => {
    socket.on('connect', () => {
      setState({
        connectionAvailability: 'success',
      });
    });

    // TODO: Copied from original room-ui/src/components/PreTest.js.
    //  Not found on https://socket.io/docs/v3/client-api/.
    //  Need to check again if deprecated
    socket.on('connect_failed', () => {
      setState({
        connectionAvailability: 'error',
      });
    });

    socket.on('connect_timeout', () => {
      setState({
        connectionAvailability: 'error',
      });
    });

    socket.on('reconnect_attempt', () => {
      setState({ connectAttempt: state.connectAttempt + 1 });
    });

    socket.on('error', () => {
      setState({
        connectionAvailability: 'error',
      });
    });

    return () => {
      setState({ connectAttempt: 0 });
      socket.disconnect();
    };
  }, [state.connectAttempt]);

  useEffect(() => {
    if (state.connectAttempt > 4) {
      setState({ connectionAvailability: 'error', connectAttempt: 0 });
      socket.disconnect();
    }
  }, [state.connectAttempt]);

  useEffect(() => {
    const statuses = ['success', 'error'];
    if (
      statuses.indexOf(state.videoAvailability) > -1 &&
      statuses.indexOf(state.micAvailability) > -1 &&
      statuses.indexOf(state.connectionAvailability) > -1
    ) {
      setState({ isTesting: false });
      socket.disconnect();
    }
  }, [
    state.connectionAvailability,
    state.micAvailability,
    state.videoAvailability,
  ]);

  return (
    <Wrapper>
      {state.isTested ? (
        <JoinRoomForm />
      ) : (
        <AvailabilityTest
          {...{
            isTesting: state.isTesting,
            videoAvailability: state.videoAvailability,
            micAvailability: state.micAvailability,
            connectionAvailability: state.connectionAvailability,
            onTest,
            setState,
          }}
        />
      )}

      {!state.isTested &&
        (state.videoAvailability === 'error' ||
          state.micAvailability === 'error') && (
          <ButtonContainer>
            <Button variant="ghost" onClick={onTest}>
              {t('views.preCallTest.restartProcess')}
            </Button>
          </ButtonContainer>
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
`;

const ButtonContainer = styled.div`
  margin-top: 16px;
  padding: 0;
`;
