import { DefaultTheme } from 'styled-components';

export const defaultTheme: DefaultTheme = {
  colors: {
    primary: {
      main: 'hsl(201, 100%, 41%)',
      light: 'hsl(201, 77%, 49%)',
      dark: 'hsl(201, 100%, 26%)',
    },
    disabled: 'hsl(0, 0%, 73%)',
    focus: 'hsl(201, 100%, 41%)',
    boxShadow: 'hsla(201, 100%, 41%, 0.5)',
    white: 'hsl(0, 0%, 100%)',
    black: 'hsl(0, 0%, 0%)',
    transparentBlack: 'hsla(0, 0%, 0%, 0.6)',
    almostBlack: 'hsl(0, 0%, 14%)',
    title: 'hsl(208, 27%, 32%)',
    text: 'hsl(208, 27%, 32%)',
    success: 'hsl(120, 61%, 34%)',
    error: 'hsl(0, 100%, 50%)',
    warn: 'hsl(48, 89%, 50%)',
    danger: 'hsl(0, 79%, 63%)',
    active: 'hsl(195, 86%, 64%)',
    iconButtonGrey: 'hsl(0, 0%, 42%)',
    gray: 'hsl(0, 0%, 42%)',
  },
  palette: {
    1: 'hsl(218, 98%, 65%)',
    2: 'hsl(205, 73%, 58%)',
    3: 'hsl(189, 57%, 50%)',
    4: 'hsl(171, 65%, 46%)',
    5: 'hsl(158, 78%, 46%)',
  },
  backgroundColor: {
    main: 'hsl(30, 11%, 96%)',
    light: 'hsla(0, 0%, 100%, 0.85)',
    header: 'hsl(180, 100%, 50%)',
    gradient: {
      main: 'linear-gradient(180deg, #1A5576 0%, #3C5E59 100%)',
      dark: 'linear-gradient(180deg, #15233A 0%, #242528 100%)',
    },
  },
  font: {
    familyNormal: 'neue-haas-grotesk-text, sans-serif',
    familyHeader: 'neue-haas-grotesk-display, sans-serif',
    baseSize: '16px',
  },
  breakpoints: {
    mobileMaxWidth: '450px',
    mobileMaxWidthLandscape: '915px',
  },
};
