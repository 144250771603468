import { useEffect } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { Minimize } from 'react-feather';

import { useComChannel } from '../../services/ComChannel';
import { RoomHeader } from '../../components/RoomHeader';
import { VideoControls } from '../../components/VideoControls';
import { useSharingState, useNotifications } from '../../hooks';
import { VideoView } from '../VideoView';
import { FormView } from '../FormSharing';
import { MediaView } from '../MediaSharing';
import { ScreenShareView } from '../ScreenSharing';
import { YsuraVideoEvents } from '../../enums';
import { Button } from '../../components/Button';

export const RoomView: React.FC = () => {
  const { NotificationContainer } = useNotifications();
  const {
    isComChannelInitialized,
    initChannel,
    onSessionExpired,
    onOrganizerTerminateSession,
    onOrganizerLoggedOut,
  } = useComChannel();
  const { roomId } = useParams();

  const {
    isFormSharing,
    isMediaSharing,
    isFullScreen,
    isParticipantsScreenShared,
    setSharingState,
  } = useSharingState();
  const isSharing =
    isFormSharing || isMediaSharing || isParticipantsScreenShared;

  const toggleFullScreen = () => {
    setSharingState({ isFullScreen: !isFullScreen });
  };

  /* ***************************************
   * Handle ComChannel setup
   ***************************************** */

  useEffect(() => {
    let unsubscribeOnTerminateSession = (): void => {};
    let unsubscribeOnOrganizerLoggedOut = (): void => {};
    let unsubscribeOnSessionExpired = (): void => {};

    unsubscribeOnSessionExpired = onSessionExpired(() => {
      // TODO: from room-ui
      // history.replace('/?e=r810');
      // window.location.reload();
      handleLogOut();
    });

    unsubscribeOnTerminateSession = onOrganizerTerminateSession(() => {
      handleLogOut();
    });

    unsubscribeOnOrganizerLoggedOut = onOrganizerLoggedOut(() => {
      handleLogOut();
    });

    if (!isComChannelInitialized) {
      initChannel({ roomId });
    }

    return (): void => {
      unsubscribeOnTerminateSession();
      unsubscribeOnOrganizerLoggedOut();
      unsubscribeOnSessionExpired();
    };
  }, [
    initChannel,
    onOrganizerLoggedOut,
    onOrganizerTerminateSession,
    onSessionExpired,
    roomId,
    isComChannelInitialized,
  ]);

  const handleLogOut = (): void => {
    const logoutEvent = new CustomEvent(YsuraVideoEvents.disconnected);
    window.dispatchEvent(logoutEvent);
  };

  return (
    <>
      <Wrapper>
        <VideoContainer isSharing={isSharing} isFullScreen={isFullScreen}>
          <RoomHeader isSharing={isSharing} />
          <VideoView isSharing={isSharing} />
          <VideoControls />
        </VideoContainer>

        <SharingContainer isVisible={isSharing} isFullScreen={isFullScreen}>
          <ButtonContainer>
            <Button
              variant="transparent"
              onClick={toggleFullScreen}
              data-testid="fullscreen_button"
              customStyles={{
                height: '40px',
                width: '40px',
                padding: '0px',
              }}
            >
              <Minimize />
            </Button>
          </ButtonContainer>

          <ViewContainer isVisible={isFormSharing}>
            <FormView />
          </ViewContainer>

          <ViewContainer isVisible={isMediaSharing}>
            <MediaView />
          </ViewContainer>

          <ViewContainer isVisible={isParticipantsScreenShared}>
            <ScreenShareView />
          </ViewContainer>
        </SharingContainer>
      </Wrapper>
      <NotificationContainer position="bottom-right" />
    </>
  );
};

const Wrapper = styled.div`
  background: ${({ theme }) => theme.backgroundColor.gradient.main};
  display: flex;
  gap: 16px;
  height: 100%;
  padding: 16px;
  width: 100%;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    flex-direction: column;
    padding: 12px;
  }
`;

type ContainerProps = {
  isSharing: boolean;
  isFullScreen: boolean;
};

const VideoContainer = styled.div<ContainerProps>`
  display: ${({ isFullScreen }) => (isFullScreen ? 'flex' : 'block')};
  flex-direction: ${({ isFullScreen }) => (isFullScreen ? 'column' : 'row')};
  border-radius: 16px;
  flex: 1 1 1px;
  height: 100%;
  max-height: 100%;
  padding: ${({ isFullScreen }) => (isFullScreen ? '16px 4px' : '16px')};
  row-gap: ${({ isSharing }) => (isSharing ? '16px' : 0)};
  background-color: ${({ theme }) => theme.colors.almostBlack};

  // height when sharing and mobile is in portrait mode
  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    max-height: ${({ isSharing }) => (isSharing ? '152px' : 'auto')};
  }

  // width when sharing and mobile is in landscape mode
  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    display: flex;
    flex-direction: column;
    max-width: ${({ isSharing }) => (isSharing ? '72px' : 'unset')};
  }
`;

type SharingContainerProps = {
  isVisible: boolean;
  isFullScreen: boolean;
};

const SharingContainer = styled.div<SharingContainerProps>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  flex: ${({ isFullScreen }) => (isFullScreen ? 'unset' : '2 1 1px')};
  width: ${({ isFullScreen }) => (isFullScreen ? 'calc(100% - 72px)' : '100%')};
  position: relative;
  border-radius: 16px;
  overflow: hidden;
`;

const ButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 10;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    display: none;
  }

  @media (max-width: ${({ theme }) =>
      theme.breakpoints.mobileMaxWidthLandscape}) and (orientation: landscape) {
    display: none;
  }
`;

type ViewContainerProps = {
  isVisible: boolean;
};

const ViewContainer = styled.div<ViewContainerProps>`
  display: ${({ isVisible }) => (isVisible ? 'block' : 'none')};
  height: 100%;
`;
