import { FC } from 'react';
import styled from 'styled-components';
import { ToastContainer, ToastPosition } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

interface NotificationContainerProps {
  position?: ToastPosition;
}

export const NotificationContainer: FC<NotificationContainerProps> = ({
  position = 'bottom-right',
}) => {
  return (
    <Wrapper data-testid="notification-container">
      <ToastNotifications
        position={position}
        autoClose={3000}
        icon={false}
        rtl={false}
        hideProgressBar
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ToastNotifications = styled(ToastContainer)`
  padding: 0;
  margin-right: 8px;
  margin-bottom: 88px;

  @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
    margin-bottom: 104px;
  }

  .Toastify__toast {
    border-radius: 16px;
    margin-bottom: 8px;
    font-family: ${({ theme }) => theme.font.familyNormal};
    color: ${({ theme }) => theme.colors.white};

    @media (max-width: ${({ theme }) => theme.breakpoints.mobileMaxWidth}) {
      margin: 0 24px 8px 24px;
    }
  }

  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.colors.danger};
  }

  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.colors.success};
  }

  .Toastify__toast--warning {
    background-color: ${({ theme }) => theme.colors.warn};
  }

  .Toastify__close-button {
    align-self: unset;
    line-height: 1;
    opacity: 1;

    > svg {
      fill: ${({ theme }) => theme.colors.white};
      height: 20px;
      width: 20px;
    }
  }
`;
