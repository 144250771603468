import { Dispatch, FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Button } from '../../../components/Button';
import { IconTypes } from '../../../components/Icon';
import { Container } from '../Container';
import { StateType } from '../type';
import { StatusIcon } from '../StatusIcon';

type TestProps = Omit<StateType, 'connectAttempt' | 'isTested'> & {
  onTest: () => void;
  setState: Dispatch<Partial<StateType>>;
};

export const AvailabilityTest: FC<TestProps> = ({
  isTesting,
  videoAvailability,
  micAvailability,
  connectionAvailability,
  onTest,
  setState,
}) => {
  const { t } = useTranslation();

  const isHardwareDisabled =
    videoAvailability === 'error' && micAvailability === 'error';

  return (
    <Container>
      <Title>{t('views.preCallTest.title')}</Title>
      <Subtitle>{t('views.preCallTest.1stSub')}</Subtitle>
      <Title>{t('views.preCallTest.reminder')}</Title>
      <Subtitle>{t('views.preCallTest.2ndSub')}</Subtitle>

      <IconWrapper>
        <StatusIcon
          status={videoAvailability}
          title={t('views.preCallTest.camera')}
          type={IconTypes.video}
        />
        <StatusIcon
          status={micAvailability}
          title={t('views.preCallTest.mic')}
          type={IconTypes.mic}
        />
        <StatusIcon
          status={connectionAvailability}
          title={t('views.preCallTest.connection')}
          type={IconTypes.connection}
        />
      </IconWrapper>

      {isHardwareDisabled && (
        <ErrorMessage>{t('views.preCallTest.allowAccess')}</ErrorMessage>
      )}

      {connectionAvailability === 'error' && (
        <>
          <ErrorMessage>{t('views.preCallTest.failedConnection')}</ErrorMessage>
          <ButtonContainer>
            <Button isLoading={isTesting} onClick={onTest}>
              {t('views.preCallTest.restartProcess')}
            </Button>
          </ButtonContainer>
        </>
      )}

      {connectionAvailability === 'idle' && (
        <ButtonContainer>
          <Button isLoading={isTesting} onClick={onTest}>
            {t('views.preCallTest.runTest')}
          </Button>
        </ButtonContainer>
      )}

      {connectionAvailability === 'success' && (
        <ButtonContainer>
          <Button
            onClick={() => setState({ isTested: true })}
            disabled={isHardwareDisabled}
          >
            {t('views.preCallTest.continue')}
          </Button>
        </ButtonContainer>
      )}
    </Container>
  );
};

const Title = styled.div`
  line-height: 18px;
  text-align: center;
  :not(:first-child) {
    margin-top: 16px;
  }
`;

const Subtitle = styled.div`
  font-weight: bold;
  line-height: 18px;
  margin-top: 16px;
  text-align: center;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
`;

const ErrorMessage = styled(Title)`
  margin-top: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 16px auto 0;
`;
