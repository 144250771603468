import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

const initialState = {
  room: {
    roomId: '',
    name: '',
    email: '',
  },
  sharing: {
    isFormSharing: false,
    isMediaSharing: false,
    isOwnScreenShared: false,
    isParticipantsScreenShared: false,
    isFullScreen: false,
  },
};

export type YRoomState = typeof initialState;
export type RoomState = typeof initialState.room;
export type SharingState = typeof initialState.sharing;

type UseYroomState = {
  yroomState: YRoomState;
  setYroomState: Dispatch<SetStateAction<YRoomState>>;
  setSharing: (sharing: SharingState) => void;
};

export const YroomContext = createContext<UseYroomState>({
  yroomState: initialState,
  setYroomState: () => {},
  setSharing: () => {},
});

export const useYroomState = (): UseYroomState => {
  return useContext(YroomContext);
};

type YroomStateProviderProps = {
  children: ReactElement;
};

export const YroomStateProvider = ({
  children,
}: YroomStateProviderProps): JSX.Element => {
  const [yroomState, setYroomState] = useState<YRoomState>(initialState);

  const setSharing = useCallback((sharingState: SharingState) => {
    setYroomState((oldState) => ({
      ...oldState,
      sharing: sharingState,
    }));
  }, []);

  const contextValue = useMemo(
    () => ({
      yroomState,
      setYroomState,
      setSharing,
    }),
    [yroomState, setYroomState, setSharing]
  );

  return (
    <YroomContext.Provider value={contextValue}>
      {children}
    </YroomContext.Provider>
  );
};
