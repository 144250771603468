import { forwardRef } from 'react';
import styled from 'styled-components';

interface OwnVideoProps {
  isSharing?: boolean;
}

export const OwnVideo = forwardRef<HTMLDivElement, OwnVideoProps>(
  ({ isSharing }, ref) => {
    return (
      <Wrapper
        data-testid="ysura-video-ownVideo"
        ref={ref}
        isSharing={isSharing}
      />
    );
  }
);

const Wrapper = styled.div<OwnVideoProps>`
  display: grid;
  align-items: center;
  justify-items: center;
  height: 100%;
  width: 100%;
  padding-right: ${({ isSharing }) => (isSharing ? '0' : '32px')};

  .OT_widget-container {
    background-color: ${({ theme }) => theme.colors.almostBlack};
  }

  .OT_audio-only {
    border-radius: 16px;

    .OT_widget-container {
      background-color: ${({ theme }) => theme.palette[1]};
    }

    .OT_video-poster {
      display: block;
      background-position: center !important;
    }
  }

  @media (max-width: 450px) {
    padding: 0;
    align-items: self-start;
    // when sharing, move own video to the bottom right area of the video container
    justify-items: ${({ isSharing }) => (isSharing ? 'flex-end' : 'center')};
    margin-top: ${({ isSharing }) => (isSharing ? '32px' : '0')};
  }

  // Necessary as OpenTok will render the video container in a nested structure.
  .OT_video-element {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    object-fit: cover;

    @media (max-width: 450px) {
      border-radius: 8px;
    }
  }

  .OT_name,
  .OT_mute {
    display: none;
  }

  .OT_audio-level-meter {
    z-index: 100;
  }
`;
